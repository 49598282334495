import { get } from "../../../Utils/Crypto";


let DEVELOPMENT_MODE = window.location.href.includes("localhost")

let api, token;


if (DEVELOPMENT_MODE) {
	api = "https://api.buildrun.app/main/employee/";

	token = () => get("refresh_token");
} else {
	api = "https://api.buildrun.app/main/employee/";
	token = () => get("refresh_token");
}


export {
	api, token, DEVELOPMENT_MODE
}
