import { Routes,Route } from "react-router"
import { Header } from "../../Common/Header";
import { Navigation } from "../../Common/TopNavigation";
import Details from './Details';
import Mis from './Mis';
import Raise from './RaiseHinderance';
import Resolve from './ResolveHinderance';

const Profile = () => {
    return(
        <>
            <Header>
                <Navigation items={[
                    {name:'Details', path:"/app/profile"},
                    {name:'Mis Report', path:'/app/profile/mis'},
                    {name:'Raise Hinderance', path:"/app/profile/hinderance"},
                    {name:'Resolve Hinderance', path:'/app/profile/resolve'}]} 
                />
            </Header>
            <Routes>
                <Route path="/" element={<Details />} />
                <Route path="/mis" element={<Mis />} />
                <Route path="/hinderance" element={<Raise />} />
                <Route path="/resolve" element={<Resolve />} />
            </Routes>
        </>
       
    )
}

export default Profile;
