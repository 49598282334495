import React from 'react'
import ReactApexChart from "react-apexcharts"
import Props from 'prop-types'

/*
	<LineGraphLabels height={400} title="Graph" data={[{name:"Hello",data:[121,222]]} items={["Min Price","Max Price"]}/>
*/

function LineGraphLabels(props) {
	return (
		<div>
			<ReactApexChart options={{
					
                    
                    // title: {
					// 	text: props.title,
					// 	align: 'left'
					// },
                    chart: {
                        height: 350,
                        type: 'line',
                        dropShadow: {
                          enabled: true,
                          color: '#000',
                          top: 18,
                          left: 7,
                          blur: 10,
                          opacity: 0.2
                        },
                        toolbar: {
                          show: false
                        }
                      },
                      colors: ['#77B6EA', '#545454','#0084ff'],
                      dataLabels: {
                        enabled: true,
                      },
                      stroke: {
                        curve: 'smooth'
                      },
                      title: {
                        text: 'Task Status',
                        align: 'left'
                      },
                      grid: {
                        borderColor: '#e7e7e7',
                        row: {
                          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                          opacity: 0.5
                        },
                      },
                      markers: {
                        size: 1
                      },
                      xaxis: {
                        categories: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
                        title: {
                          text: 'Week'
                        }
                      },
                      yaxis: {
                        title: {
                          
                        },
                        min: 5,
                        max: 40
                      },
                      legend: {
                        position: 'top',
                        horizontalAlign: 'right',
                        floating: true,
                        offsetY: -25,
                        offsetX: -5
                      }
			}} series={[
                {
                  name: "Task Assigned",
                  data: [25, 36, 28, 38, 31, 33, 35]
                },
                {
                  name: "Task Completed",
                  data: [14, 18, 16, 19, 23, 11, 20]
                }
              ]} type="line" height={props.height} />
		</div>
	)
}


LineGraphLabels.propTypes = {
	
	title:Props.string.isRequired,
	height:Props.number.isRequired
}

LineGraphLabels.defaultProps = {
	y: "Price",
	title:'Price Movement',
	height:350,
}

export {
	LineGraphLabels
}

export default LineGraphLabels
