import { CalendarView } from "../../Component/CalendarView";
import { useTableView } from "../../Hooks/TableView"



const NormalTable = (props) => {
    let tableView = useTableView();
    
    if ( props.view ) {
        tableView = props.view;
    }
    let {data} = props;

    if(tableView === 'card') {
        return(
            <div style={{display:'flex',flexWrap:'wrap',marginTop:20,marginRight:20,marginLeft:20}}>
                {data.map(props.mapCard)}
            </div>
        ) 
    } else if(tableView==='list') {
        return(
            <div style={{marginLeft:20,marginRight:20,marginTop:20}}>
                {data.map(props.mapList)}
            </div>
        )
    } else  {
        return(
            <CalendarView data={props.calendar} />
        )

    }
    

  


}

export {NormalTable}