import Props from 'prop-types';

const ProjectCardLayout = (props) => {

    let width = 100;
    width = width/props.layoutNumber;

    return(
        <div style={{width:`${width}%`,padding:10}}>
            <div onClick={props.onClick?props.onClick:{}} style={{width:'100%',cursor:'pointer',border:'1px solid var(--border-color)',borderRadius:12,height:190}}>
                <div  style={{height:40,display:'flex',justifyContent:'flex-end',borderBottom:'1px solid var(--border-color)',padding:10,borderTopLeftRadius:12,borderTopRightRadius:12}}>
                </div>
                <div style={{padding:10,position:'relative'}}>
                    <div style={{
                        background:'var(--white-color)',
                        width:40,
                        height:40,
                        borderRadius:'50%',
                        display:'flex',
                        alignItems:'center',
                        justifyContent:'center',
                        fontWeight:'700',
                        boxShadow:'2px 2px 20px -5px rgba(0,0,0,0.3)',
                        textTransform:'uppercase',
                        position:'absolute',
                        top:-24
                    }}>{props.title.slice(0,1)}</div>
                     <p style={{fontWeight:'600',marginLeft:5,marginTop:17,color:'var(--text-color)',fontSize:18}}>{props.title}</p>
                     <p style={{marginLeft:5,color:'var(--text-light-color)',fontSize:14}}>{props.description}</p>

                     <div style={{marginTop:10}}>
                        {props.children}
                    </div>
                </div>
               
            </div>
        </div>
    )
}

ProjectCardLayout.propsTypes = {
    layoutNumber:Props.number
}

ProjectCardLayout.defaultProps = {
    layoutNumber:4
}



export {ProjectCardLayout}