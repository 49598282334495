import Props from 'prop-types';
import {useLocation,useNavigate} from 'react-router-dom';
import './index.css';

const Navigation = ({items}) => {

    let path = useLocation().pathname;
    let navigate = useNavigate();

    let mapLineItem = (item,index)=>{
        let active = item.path === path;
        return(
            <div onClick={()=>{
                navigate(item.path)
            }} key={index} className={`top-navigation-item ${active?'top-navigation-active-item':null}`}>
                {item.name}
            </div>
        )
    }
    return(
        <div style={{
            display:'flex',
            paddingLeft:20,
            alignItems:'flex-end',
            height:'inherit',
            borderBottom:'1px solid var(--border-color)'
        }}>
            {items.map(mapLineItem)}
        </div>
    )

}


Navigation.defaultProps = {
    items:[]
}

Navigation.propsTypes = {
    items:Props.array.isRequired
}

export {Navigation}