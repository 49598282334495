import { useRef, useState } from "react";
import toast from "react-hot-toast";
import { NormalButton } from "../../../Common/Button/NormalButton";
import { SearchProject } from "../../../Common/Search/SearchProject";
import { SearchWbs } from "../../../Common/Search/SearchWbs";
import { BoxWrapper } from "../../../Common/Wrapper/BoxWrapper";
import { post } from "../../../Model/Network/Config/Axios";


const Index = () => {


    const [buttonLoading,setButtonLoading] = useState(false);
    let PROJECT = useRef(),WBS=useRef();


    let submit = () => {
        let taskMappingId = WBS.current.getValue()?WBS.current.getValue().id:null
        let ProjectId = PROJECT.current.getValue()?PROJECT.current.getValue():undefined;

        if(!ProjectId) {
            toast.error("Please select the project");
            return;
        }
       
        if(!taskMappingId) {
            toast.error("Please select the wbs item");
            return;
        }
        
        setButtonLoading(true);

        post(`/misc/resolveHindrance`,{taskMappingId},(e,r)=>{
            if(r) {
                setButtonLoading(false);
                WBS.current.setValue('');
                PROJECT.current.setValue('');
                toast.success('Hinderance resolve successfully');
            } else {
                setButtonLoading(false);
                toast.error(e.data.message);
            }
        })


    }

    return(
        <BoxWrapper style={{borderRadius:4,margin:20}}>
            <SearchProject ref={PROJECT} title="Search Project" />
            <SearchWbs projectId={PROJECT} ref={WBS} title={`Search Wbs`} />
            <NormalButton loading={buttonLoading} onClick={submit} text="Submit" style={{maxWidth:350}}/>
        </BoxWrapper>
    )
}

export default Index;