import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import interactionPlugin from "@fullcalendar/interaction" // needed for dayClick
import {  useEffect, useRef, useState } from 'react';
import { TaskView } from '../TaskView'
import './index.css';
import { RefObject } from '../../Utils/Refrence';


const CalendarView = (props) => {

    const [show,setShow] = useState(false);
    let calendarApi = useRef();


    useEffect(()=>{
         RefObject.setRef(calendarApi.current.getApi())
    },[])
    

    return(
       <div style={{background:"#fff",height:'inherit'}}>
        <FullCalendar
            plugins={[ dayGridPlugin,interactionPlugin ]}
            ref={calendarApi}
            initialView="dayGridMonth"
            eventClick={(e)=>{
                setShow(e.event._def)
            }}
            events={props.data}
        />
        {
            show ?
            <TaskView 
                item={{name:show.title,id:show.publicId}}   
                close={()=>{setShow(false)}}             
                />
            : null
        }
      </div>
    )
}

export {CalendarView}