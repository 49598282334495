import './index.css';

const Header = (props) => {

    return(
        <div className="header">
            {props.children}
        </div>
    )
}


export {Header}