import {Routes,Route} from 'react-router-dom';
import {Provider} from 'react-redux';
import Login from './Login';
import MobileLogin from './Login/MobileLogin';
import AuthenticateController from './AuthenticatedController/index';
import store from '../Reducer/index';

const Index = () => {


  return(
    <Provider store={store}>
        <Routes>
            <Route path="/" element={<Login />} exact />
            <Route path="/mobile" element={<MobileLogin />} exact />
            <Route path="/app/*" element={<AuthenticateController />}  />
        </Routes>
    </Provider>
  
  )
}

export default Index;