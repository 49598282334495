import { useRef, useState } from "react";
import toast from "react-hot-toast";
import { NormalButton } from "../../../Common/Button/NormalButton";
import { FInput } from "../../../Common/FInput";
import { RightModal } from "../../../Common/Modal/RightModal";
import { post } from "../../../Model/Network/Config/Axios";
import { SearchVendor } from "../../../Common/Search/SearchVendor";
import { SearchLabour } from "../../../Common/Search/SearchLabour";
import { SearchProject } from "../../../Common/Search/SearchProject";

const AddAttendanceModal = (props) => {

    const [buttonLoading,setButtonLoading] = useState(false);

    let VENDOR = useRef(), 
        LABOUR = useRef(),
        DATE = useRef(),
        COUNT = useRef(),
        REMARKS = useRef(),
        PROJECT = useRef();

    let _add = () => {
        let project = PROJECT.current.getValue(),
            vendor = VENDOR.current.getValue(),
            labour = LABOUR.current.getValue(),
            date = DATE.current.value,
            count = COUNT.current.value,
            remarks = REMARKS.current.value;

        if(!project) {
            toast.error("Please select project");
            return;
        }

        if (!vendor) {
            toast.error("Please select vendor")
            return;
        }

        if (!labour) {
            toast.error("Please select labour")
            return;
        }

        if (!date) {
            toast.error("Please select date")
            return;
        }

        if (!count) {
            toast.error("Please select count")
            return;
        }

        if (!remarks) {
            toast.error("Please add remarks")
            return;
        }

        date = new Date(date);

        let data = {
            "vendorId": vendor.id,
            "resourceId": labour.id,
            "date": Date.parse(date),
            "count": Number(count),
            "remarks": remarks
        }

        setButtonLoading(true);

        post(`labour/addAttendance/${project.id}`, data, (e, r) => {
            if (r) {
                toast.success("Attendance Added Successfully");
                setButtonLoading(false);
                props.close();
            }

            if (e) {
                setButtonLoading(false);
                toast.error(e.data.message);
            }
        })
    }


    return(
        <>
            <RightModal 
                close={props.close}
                title="Add Attendance">
                <SearchProject title="Search Project" ref={PROJECT} />
                <SearchVendor title="Search Vendor" url="/misc/searchVendor" ref={VENDOR}/>
                <SearchLabour title="Search Labour" url="/labour/searchLabourResources" ref={LABOUR}/>
                <FInput title="Date" ref={DATE} type="date" />
                <FInput title="Count" ref={COUNT} type="number" />
                <FInput title="Remarks" ref={REMARKS} />

                <NormalButton onClick={_add} text="Create" loading={buttonLoading} />
            </RightModal>
        </>
    )
}

export default AddAttendanceModal;
