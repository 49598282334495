

const Index = (props) => {

    let {data} = props;


    let mapHeaders = (item,index) => {
        let flex = 1;
        if(index===0) {
            flex = 2
        }
        return(
            <div key={index} style={{flex,padding:10,borderRight:'1px solid var(--border-color)'}}>
                {item}
            </div>
        )
    }


    let mapRows = (item,index) => {
        let flex = 1;
        if(index===0) {
            flex = 2
        }
        return(
            <div key={index} style={{flex,minHeight:60,display:'flex',color:item.colour,alignItems:'center',fontSize:14,padding:10,borderRight:'1px solid var(--border-color)'}}>
                {item.item}
            </div>
        )
    }


    return(
        <div style={{padding:20,height:'100vh',overflowY:'scroll',paddingBottom:100}}>
            <div style={{display:'flex',alignItems:'center',background:'rgba(255,255,255,0.7)',border:'1px solid var(--border-color)'}}>
                 {data.headers.map(mapHeaders)}
            </div>
            {data.rows.map((val,index)=>(
                <div key={index} style={{display:'flex',alignItems:'center',background:'rgba(255,255,255,0.7)',border:'1px solid var(--border-color)'}}>
                        {val.map(mapRows)}
                 </div>
            ))}
        </div>
    )
}

export default Index;