import { forwardRef, useEffect, useRef, useState, useImperativeHandle } from "react"
import { get } from "../../../Model/Network/Config/Axios";
import { FInput } from "../../FInput"


const SearchLabour = forwardRef((props,ref) =>{
    const [text,setText] = useState('');
    const [allOptions, setAllOptions] = useState([]);
    const [item,setItem] = useState(undefined);
    let NAME = useRef();

    useImperativeHandle(ref,()=>({
        getValue:()=>{
            return item
        },
        setValue:()=>{
            setItem('')
        }
    }))

    useEffect(()=>{
        if(text.length>0) {
            get(props.url, (e, r) => {
                // sample list -> 
                if (r) {
                    setAllOptions(r.resources);
                }
            }, {
                q: text
            })
        } else {
            setAllOptions([]);
        }

    },[text])



    return(
        <div style={{position:'relative'}}>
            <FInput ref={NAME} title={props.title} onChange={setText} />
            {
                allOptions.length>0 
                ? (
                    <div className="suggestion-modal" style={{marginTop:21}}>
                        {
                            allOptions.map((val,index)=>(
                                <div 
                                    key={index} 
                                    className="suggestion-modal-item" 
                                    onClick={()=>{
                                        setItem(val);
                                        NAME.current.value = val.name;
                                        setAllOptions([]);
                                    }}
                                >
                                    <div>
                                        <p>{val.name}</p>
                                        <p style={{fontSize:12,color:'var(--text-light-color)'}}>{val.email}</p>
                                    </div>
                                </div>)
                            )
                        }
                    </div>
                ) : null
            }
        </div>
    )
})

export {SearchLabour}