import React, { useEffect, useRef, useState } from 'react';
import {useNavigate} from 'react-router-dom';
import {NormalButton} from '../../Common/Button/NormalButton/index';
import { FInput } from '../../Common/FInput';
import {toast} from 'react-hot-toast';
import { post } from '../../Model/Network/Config/Axios';
import {useDispatch, useSelector} from 'react-redux';
import { AuthAction } from '../../Reducer/Authorization';
import { set } from '../../Utils/Crypto';
import Forgot from './Forgot';

const Index = () => {

  const [step] = useState(1);
  const [buttonLoading,setButtonLoading] = useState(false);
  let data = useSelector((Config)=>Config.Config);
  let EMAIL = useRef(),PASSWORD=useRef();
  const [forgot,setForgot] = useState(false);
  let dispatch = useDispatch();
  let history=useNavigate();


  useEffect(()=>{
    if(data && data.authenticated) {
        history('/app/home')
    }
  },[data,history])

  
  let step1 = () => {
      return(
          <>
            <FInput ref={EMAIL} type="email" title="Email" placeholder="Enter the email address" />
            <FInput ref={PASSWORD} type="password" title="Password" placeholder="Enter the password" />
            <NormalButton text="Login" loading={buttonLoading} style={{marginTop:40}} onClick={()=>{
                let email = EMAIL.current.value;
                let password = PASSWORD.current.value;
                if(!password) {
                    toast.error("Please enter the email")
                    return;
                }
                if(!email) {
                    toast.error("Please enter the email")
                    return;
                }
                setButtonLoading(true);
                post(`auth/login`,{username:email,password},(e,r)=>{
                    if(r){
                        set('loggedin','yes');
                        set('token',r.access_token);
                        set('refresh_token',r.refresh_token);
                        set('name',r.name);
                        dispatch({
                            type:AuthAction.Login,
                            data:r
                        })
                        setButtonLoading(false);
                    } else {
                        setButtonLoading(false);
                        toast.error(e.data.message)
                    }
                })
            }} />
            <p style={{textAlign:'center',fontWeight:'bold',marginTop:10,marginBottom:10}}>OR</p>
            <NormalButton onClick={()=>{history('/mobile')}} text="Mobile Number Login"  style={{backgroundColor:'#17a2b8'}}/>
            <div onClick={()=>setForgot(true)} style={{textAlign:'right',cursor:'pointer',fontWeight:"600",marginTop:25,color:'var(--danger-color)'}}>Forgot Password ?</div>
          </>
      )
  }



  if(forgot) {
      return <Forgot reset={()=>{setForgot(false)}} />
  }
  

  return (
    <div style={{height:"100vh",background:"#f2f2f2"}}>
        <div style={{height:'100vh',display:'flex',alignItems:'center',justifyContent:'center'}}>
            <div style={{
                height:580,
                width:430,
                borderRadius:16,
                padding:25,
                background:"#fff"
            }} className="box-shadow">
                
                <p style={{textAlign:'center',fontWeight:'600',fontSize:28}}>User Login</p>
                <p style={{textAlign:'center',color:'var(--text-light-color)',fontSize:15,marginTop:10}}>Hey, Enter your details to get sign in <br></br> to your account</p>

                <div style={{marginTop:70}}>
                    {
                        step === 1 ?
                        step1()
                        :null
                    }
                </div>
                
            </div>
        </div>
    </div>
  )
}


export default Index