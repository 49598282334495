import { CalendarView } from "../../Component/CalendarView";
import { useTableView } from "../../Hooks/TableView"



const PaginationTable = (props) => {

    const tableView = useTableView();
    let {data} = props;


    let type = () => {
        if(tableView === 'card') {
            return(
                <div style={{display:'flex',flexWrap:'wrap',marginTop:20,marginRight:20,marginLeft:20}}>
                    {data.map(props.mapCard)}
                </div>
            ) 
        } else if(tableView==='list') {
            return(
                <div style={{marginLeft:20,marginRight:20,marginTop:20}}>
                    {data.map(props.mapList)}
                </div>
            )
        } else  {
            return(
                <CalendarView data={props.calendar} />
            )
    
        }
    
    }

    let start = 1+props.page*props.size;
    let end = (props.page)*props.size+props.size;
    if(props.size>props.data.length) {
        end = props.data.length
    }
   
    return(
        <>
            <div style={{display:'flex',alignItems:'center',justifyContent:'flex-end',paddingRight:20,paddingTop:20}}>
                <p style={{fontWeight:'600',margin:'0px 2px'}}>{start?start:1}</p>
                <p>-</p>
                <p style={{fontWeight:'600',margin:'0px 2px'}}>{end}</p>
                <p style={{margin:'0px 5px'}}>of</p>
                <p style={{fontWeight:'600',margin:'0px 2px'}} >{props.total}</p>

                <p  onClick={()=>{
                      if(props.page>0) {
                        props.next(props.page-1)
                    }
                }} className="box-shadow" style={{background:'#fff',cursor:'pointer',padding:'2px 10px',fontWeight:600,fontSize:15,borderRadius:4,margin:"0px 5px"}}>Prev</p>
                <p onClick={()=>{
                    if(props.data.length>(props.page*1)*props.size) {
                        props.next(props.page+1)
                    }
                }} className="box-shadow" style={{background:'#fff',cursor:'pointer',padding:'2px 10px',fontWeight:600,fontSize:15,borderRadius:4,margin:"0px 5px"}}>Next</p>

            </div>
            {type()}
        </>
    )
    
}

export {PaginationTable}