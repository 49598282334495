import { useEffect, useState } from "react";
import {useParams} from 'react-router-dom';
import { TaskCard, TaskList } from "../../../Common/CardLayout/TaskCard";
import { FilterOutline } from "../../../Common/Filter/FilterOutline";
import { PageLoader } from "../../../Common/PageLoader";
import { NormalTable } from "../../../Common/Tables/NormalTable";
import { TaskView } from "../../../Component/TaskView";
import { useProject } from "../../../Hooks/ProjectSelect";
import { get } from "../../../Model/Network/Config/Axios";


const Index = () => {

    const [loading,setLoading] = useState(true);
    const [items,setItems] = useState([]);
    const [show,setShow] = useState(false);
    const project = useProject()
    const {id} = useParams();


    useEffect(()=>{
        INIT(project)
    },[])


    let INIT = () => {
        let url = `task/toDoWbsItems`;
        if(project) {
            url = `task/toDoWbsItems?projectId=${project.id}`
        }
        get(`${url}`,(e,r)=>{
            if(r) {
                let obj = {};
                 Object.keys(r.wbsItemTaskItemMap).forEach(val=>{
                     obj[val.trim()] = r.wbsItemTaskItemMap[val]
                    
                 })
                 let task = obj[id];
                setItems(task?task:[]);
                setLoading(false);
            }
        })
    }

    let mapCard = (item,index) => {
        return(
            <TaskCard 
                size="M" 
                key={index} 
                title={item.name} 
                chip={item.isSnag ? 'Snag' : item.isPaused ? 'Paused' : item.workStarted ? 'Live' : 'Not Started'}
                backChip={item.isSnag ? {background:'var(--other-color)'} : item.isPaused ? {background:'var(--warning-color)'} : item.workStarted ? {background:'var(--success-color)'} : {background:'var(--theme-main-color)'}}
                onClick={(e)=>{
                    e.stopPropagation();
                    setShow(item)
                }}
            />

        )
    }

    let mapList = (item,index) => {
        return(
            <TaskList 
                title={item.name} 
                index={index}
                key={index} 
                chip={item.isSnag ? 'Snag' : item.isPaused ? 'Paused' : item.workStarted ? 'Live' : 'Not Started'}
                backChip={item.isSnag ? {background:'var(--other-color)'} : item.isPaused ? {background:'var(--warning-color)'} : item.workStarted ? {background:'var(--success-color)'} : {background:'var(--theme-main-color)'}}
                onClick={(e)=>{
                    e.stopPropagation();
                    setShow(item)
                }}
                
            />
        )
    }


    if(loading) {
        return <PageLoader />
    }


    return(
        <>
            <FilterOutline />
            <div style={{height:'calc(100vh - 110px)',overflowY:'auto'}}>
                <NormalTable data={items} mapCard={mapCard} mapList={mapList} />
            </div>
            {
                show?
                <TaskView 
                    close={()=>setShow(false)}
                    item={show}
                    onUpdate={()=>{INIT(project)}}
                />
                :null
            }
        </>
    )

}


export default Index;