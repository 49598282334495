import { useEffect, useState } from "react";
import {useNavigate} from 'react-router-dom'
import { NormalButton } from "../../../Common/Button/NormalButton";
import { PageLoader } from "../../../Common/PageLoader";
import { TitleHeader } from "../../../Common/Title";
import { useProject } from "../../../Hooks/ProjectSelect";
import { get } from "../../../Model/Network/Config/Axios";
import GraphTrends from './GraphTrends/index';
import CreateAdhocTask from './CreateAdhocTask';
import { useDictionary } from "../../../Hooks/Dictionary";
import { Card } from "../../../Common/CardLayout/Card";
import { FInput } from "../../../Common/FInput";
import ViewDPR from "./Dpr/ViewDPR";


const Main = () => {


    const [loading,setLoading] = useState(true);
    const [items,setItems] = useState([]);
    const [allItems,setAllItems] = useState([]);
    const [addAdhoc,setAdhocTask] = useState(false);
    const [viewDPR, setViewDPR] = useState(false);
    const [searchQuery,setSearchQuery] = useState('');
    let Navigate = useNavigate();
    let dictionary = useDictionary();
    let project = useProject();

    useEffect(()=>{
        INIT()
    },[project])



    let INIT = () => {
        let url = `task/toDoWbsItems`;
        if(project) {
            url = `task/toDoWbsItems?projectId=${project.id}`
        }
        get(`${url}`,(e,r)=>{
            if(r) {
                let item = [];
                Object.keys(r.wbsItemTaskMap).forEach(val=>{
                    item.push(val);
                })
                setItems(item);
                setAllItems(r.wbsItemTaskMap);
                setLoading(false);
            }
        })
    }

    let mapItem  = (item,index) => {
        return(
          <Card onClick={()=>{
                Navigate(`/app/home/task/${item}`)
          }} key={index} layout={4} title={item} desc={`${allItems[item].length} ${dictionary('task')}`}>

          </Card>
        )
    }

    let filter = (items) => {
        if(searchQuery.length>2) { 
            return items.filter(val=>{
                if(val.toLowerCase().includes(searchQuery.toLowerCase())) {
                    return true
                } else {
                    return false
                }
            })
        }
        return items;
    }

    if(loading) {
        return <PageLoader />
    }
    
    return(
        <div style={{padding:20,height:'calc(100vh - 60px)',overflowY:'auto'}}>
            <GraphTrends />
            <div style = {{margin:0,marginLeft:12,marginRight:12,marginTop:40}}>
                <div className="flex flex-v-center" style={{justifyContent:'space-between'}}>
                    <TitleHeader title="Work Visit" />
                    <NormalButton onClick={()=>{setAdhocTask(true)}} text={`Create Adhoc ${dictionary('task')}`} style={{maxWidth:180,marginTop:0, marginRight: 10}}/>
                    <NormalButton onClick={()=>{setViewDPR(true)}} text={`View DPR`} style={{maxWidth:180,marginTop:0}}/>
                </div>
                <div style={{marginTop:20}}>
                    <FInput onChange={setSearchQuery} placeholder="Search Here for your WBS Item" />
                </div>
                <div className="flex" style={{flexWrap:'wrap',marginTop:40}}>
                    {
                        filter(items).map(mapItem)
                     }
                </div>
                
            </div>
            {
                addAdhoc ?
                <CreateAdhocTask 
                    close={()=>{
                        setAdhocTask(false)
                    }}
                />
                : null
            }
            {
                viewDPR ? 
                    <ViewDPR
                        close={() => {
                            setViewDPR(false);
                        }}
                    /> : null
            }
        </div>
    )
}

export default Main;
