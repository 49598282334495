import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react"
import { get } from "../../../Model/Network/Config/Axios";
import { FInput } from "../../FInput"


const SearchProject = forwardRef((props,ref) =>{


    const [text,setText] = useState('');
    const [project,setProject] = useState([]);
    const [item,setItem] = useState(undefined);
    let NAME = useRef();

    useImperativeHandle(ref,()=>({
        getValue:()=>{
            return item;
        },
        setValue:(value)=>{
            NAME.current.value=value
        }
    }))

    useEffect(()=>{
        if(text.length>0) {
            get(`task/adhocTask/project.search`, (e, r) => {
                if (r) {
                   setProject(r.projects)
                }
            }, {
                q: text
            })
        } else {
            setProject([])
        }

    },[text])

  

    return(
        <div style={{position:'relative'}}>
            <FInput ref={NAME} title={props.title} onChange={setText} placeholder={props.placeholder}/>
            {
                project.length>0 ?
                <div className="suggestion-modal" style={{marginTop: props.title ? 21 : 0}}>
                    {project.map((val,index)=>(
                    <div key={index} className="suggestion-modal-item" onClick={()=>{
                        setItem(val);
                        NAME.current.value = val.name;
                        setProject([]);
                        }}>
                        <div>
                            <p>{val.name}</p>
                            <p style={{fontSize:12,color:'var(--text-light-color)'}}>{val.city}</p>
                        </div>
                    </div>))}
                </div>:
                null
            }
        </div>
    )
})

export {SearchProject}