import Props from 'prop-types';
import './index.css';

const TopLine = ({items}) => {


    let mapLineItem = (item,index)=>{
        return(
            <div onClick={item.onClick} key={index} className={`line-navigation-item ${item.active?'line-navigation-active-item':null}`}>
                {item.name}
            </div>
        )
    }

    return(
        <div style={{
            display:'flex',
            alignItems:'flex-end',
            borderBottom:'1px solid var(--border-color)'
        }}>
            {items.map(mapLineItem)}
        </div>
    )

}


TopLine.defaultProps = {
    items:[]
}

TopLine.propsTypes = {
    items:Props.array.isRequired
}

export {TopLine}