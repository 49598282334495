import { get } from "../../Model/Network/Config/Axios"
import { AuthAction } from "../Authorization"


export function configController() {
    return function (dispatch) {
        get('auth/config', (e, r) => {
            if (r) {
                dispatch({
                    type: AuthAction.Config,
                    data: r
                })        
            }
        })
    }
}