import { useRef, useState } from "react";
import toast from "react-hot-toast";
import Moment from 'moment';
import { Attachment } from "../../../Common/Attachment";
import { NormalButton } from "../../../Common/Button/NormalButton";
import { FInput } from "../../../Common/FInput";
import { RightModal } from "../../../Common/Modal/RightModal"
import { SearchEmployee } from "../../../Common/Search/SearchEmployee";
import { SearchProject } from "../../../Common/Search/SearchProject";
import { SearchTask } from "../../../Common/Search/SearchTask";
import { SearchWbs } from "../../../Common/Search/SearchWbs";
import { post } from "../../../Model/Network/Config/Axios";

const Index = (props) => {

    const [buttonLoading,setButtonLoading] = useState(false);
    let LABOUR = useRef(),TOTALDAYS=useRef(),REMARKS=useRef(),PLANNEDSTARTDATE=useRef(),WBS=useRef(),ADHOC=useRef(),APPROVER=useRef(),RESPONSIBLE=useRef(),PROJECT=useRef(),TASK=useRef();


    let _add = () => {
        let projectId = PROJECT.current.getValue();
        let taskId = TASK.current.getValue();
        let wbsItemMappingId = WBS.current.getValue();;
        let responsiblePerson = RESPONSIBLE.current.getValue();
        let approver = APPROVER.current.getValue();
        let totalLabourRequired = LABOUR.current.value
        let totalDays = TOTALDAYS.current.value;
        let adhocImage = ADHOC.current.getValue;
        let adhocRemarks = REMARKS.current.value;
        let plannedStartDate = PLANNEDSTARTDATE.current.value;


        if (!projectId) {
            toast.error("Please select the Project")
            return;
        }

        if (!taskId) {
            toast.error("Please select the task")
            return;
        }
        if (!wbsItemMappingId) {
            toast.error("Please select the wbs item")
            return;
        }
        if (!responsiblePerson) {
            toast.error("Please select the responsible person")
            return;
        }
        if (!approver) {
            toast.error("Please select the approver")
            return;
        }

        if (!totalDays) {
            toast.error("Please enter the total days")
            return;
        }

        if(!plannedStartDate) {
            toast.error("Please select the planned start date");
            return;
        }



        let data = {
            "projectId": +projectId.id,
            "taskId": taskId.id,
            "wbsItemMappingId": wbsItemMappingId.id,
            "responsiblePerson": responsiblePerson.employeeCode,
            "approver": approver.employeeCode,
            "totalLabourRequired": totalLabourRequired ? +totalLabourRequired : 0,
            "totalDays": +totalDays,
            "plannedStartDate": Moment(plannedStartDate,'YYYY-MM-DD').valueOf(),
            "adhocImage": adhocImage[0],
            "adhocRemarks": adhocRemarks ? adhocRemarks : null
        }

        setButtonLoading(true);

        post(`/task/adhocTask/create`, data, (e, r) => {
            if (r) {
                toast.success("Adhoc Task Created Successfully");
                setButtonLoading(false);
                props.close();
            }

            if (e) {
                setButtonLoading(false);
                toast.error(e.data.message);
            }
        })

    }


    return(
        <>
            
            <RightModal 
                close={props.close}
                title="Create Adhoc Task">
                <SearchProject title="Search Project" ref={PROJECT} />
                <SearchTask title="Search Task" ref={TASK} />
                <SearchWbs projectId={PROJECT} ref={WBS} title="Search Wbs" />
                <SearchEmployee title="Responsible Person" ref={RESPONSIBLE} />
                <SearchEmployee title="Approver" ref={APPROVER} />
                <FInput title="Planned Start Date" ref={PLANNEDSTARTDATE} type="date"   />
                <FInput title="Total Labour Required" ref={LABOUR} type="number"   />
                <FInput title="Total Days" ref={TOTALDAYS} type="number"  />
                <FInput title="Adhoc Remarks" ref={REMARKS}   />
                <Attachment ref={ADHOC} />

                <NormalButton onClick={_add} text="Create" loading={buttonLoading} />


               
            </RightModal>
        </>
    )
}

export default Index;