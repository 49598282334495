import Moment from 'moment';
import Props from 'prop-types';

const DateCard = (props) => {

    let width = 100;
    width = width/props.layout;

    return(
        <div style={{width:`${width}%`}}>
            <div style={{padding:16,borderRadius:8,marginRight:10,border:'1px solid var(--border-color)'}}>
                <p style={{fontSize:13,letterSpacing:0.5,color:'var(--text-light-color)'}}>{props.title}</p>
                <p style={{fontSize:22,fontWeight:'600',marginTop:5}}>{props.date ?Moment(props.date).format('DD-MM-YYYY'):"-"}</p>
            </div>
        </div>
    )
}

DateCard.defaultProps = {
    layout:3
}

DateCard.propsTypes ={
    title:Props.string.isRequired,
    date:Props.any.isRequired
}

export {DateCard}