import React, { useEffect, useRef, useState } from 'react';
import {useNavigate} from 'react-router-dom';
import {NormalButton} from '../../Common/Button/NormalButton/index';
import { FInput } from '../../Common/FInput';
import {toast} from 'react-hot-toast';
import { post } from '../../Model/Network/Config/Axios';
import {useDispatch, useSelector} from 'react-redux';
import { AuthAction } from '../../Reducer/Authorization';
import { set } from '../../Utils/Crypto';
import Forgot from './Forgot';

const Index = () => {

  const [step] = useState(1);
  const [buttonLoading,setButtonLoading] = useState(false);
  const [otp,setOtp] = useState(false);
  let data = useSelector((Config)=>Config.Config);
  let MOBILE = useRef(),OTP=useRef();
  const [forgot,setForgot] = useState(false);
  let dispatch = useDispatch();
  let history=useNavigate();


  useEffect(()=>{
    if(data && data.authenticated) {
        history('/app/home')
    }
  },[data,history])

  
  let step1 = () => {
      return(
          <>
            <FInput disabled={otp} ref={MOBILE} type="number" title="Mobile Number" placeholder="Enter the mobile number" />
            {
                otp ?
                <FInput ref={OTP} type="number" title="Enter OTP" placeholder="Enter the otp" />
                :null

            }
            <NormalButton text={otp?"Login":"Send OTP"} loading={buttonLoading} style={{marginTop:40}} onClick={()=>{

                if(!otp) {
                    let mobile = MOBILE.current.value;
                    if(!mobile) {
                        toast.error("Enter the mobile number");
                        return;
                    }
                    if(mobile.length!==10) {
                        toast.error("Enter the 10 digit mobile number");
                        return;
                    }
                    setButtonLoading(true);
                    post(`auth/mobileOtp`,{mobile},(e,r)=>{
                        if(r) {
                            toast.success("OTP sent successfully");
                            setButtonLoading(false);
                            setOtp(true);
                        }
                    })
                } else {
                    let mobile = MOBILE.current.value;
                    let otp = OTP.current.value;
                    if(!otp) {
                        toast.error("Enter the otp");
                        return;
                    }
                    setButtonLoading(true);
                    post(`auth/mobileLogin`,{mobile,otp},(e,r)=>{
                        if(r){
                            set('loggedin','yes');
                            set('token',r.access_token);
                            set('refresh_token',r.refresh_token);
                            set('name',r.name);
                            dispatch({
                                type:AuthAction.Login,
                                data:r
                            })
                            setButtonLoading(false);
                        } else {
                            setButtonLoading(false);
                            toast.error(e.data.message)
                        }
                    })
                }
                
            }} />
            <div onClick={()=>history('/')} style={{textAlign:'right',cursor:'pointer',fontWeight:"600",marginTop:25,color:'var(--danger-color)'}}>Go Back ?</div>

          </>
      )
  }



  if(forgot) {
      return <Forgot reset={()=>{setForgot(false)}} />
  }
  

  return (
    <div style={{height:"100vh",background:"#f2f2f2"}}>
        <div style={{height:'100vh',display:'flex',alignItems:'center',justifyContent:'center'}}>
            <div style={{
                height:480,
                width:430,
                borderRadius:16,
                padding:25,
                background:"#fff"
            }} className="box-shadow">
                
                <p style={{textAlign:'center',fontWeight:'600',fontSize:28}}>User Login</p>
                <p style={{textAlign:'center',color:'var(--text-light-color)',fontSize:15,marginTop:10}}>Hey, Enter your details to get sign in <br></br> to your account</p>

                <div style={{marginTop:70}}>
                    {
                        step === 1 ?
                        step1()
                        :null
                    }
                </div>
                
            </div>
        </div>
    </div>
  )
}


export default Index