import {  useState } from "react";
import { useDispatch } from "react-redux";
import card from './card.png';
import list from './list.png';
import calendar from './calendar.png';
import './index.css';
import { set,get } from "../../Utils/Crypto";
import { AuthAction } from "../../Reducer/Authorization";

const TableView = () => {

    const [type,setType] = useState(get('table-view')?get('table-view'):'card');
    let dispatch = useDispatch();

    return(
        <div style={{display:'flex'}}>
            <div onClick={()=>{
                set('table-view','card');
                dispatch({
                    type:AuthAction.Table,
                    data:'card'
                })
                setType('card')
                }} className={`tableview ${type==='card'?'active':null}`} style={{marginRight:10}}>
                <img src={card}  alt="" />
            </div>
            <div onClick={()=>{
                set('table-view','list')
                dispatch({
                    type:AuthAction.Table,
                    data:'list'
                })
                setType('list')
                }} className={`tableview ${type==='list'?'active':null}`} style={{marginRight:10}}>
                <img src={list}  alt=""  />
            </div>
            <div onClick={()=>{
                set('table-view','calendar')
                dispatch({
                    type:AuthAction.Table,
                    data:'calendar'
                })
                setType('calendar')
                }} className={`tableview ${type==='calendar'?'active':null}`}>
                <img src={calendar}  alt=""  />
            </div>
        </div>
    )
}




export {TableView}