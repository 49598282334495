import { useEffect, useRef, useState } from "react";
import Moment from 'moment';
import { TaskCard, TaskList } from "../../../Common/CardLayout/TaskCard";
import { FilterOutline } from "../../../Common/Filter/FilterOutline";
import { PageLoader } from "../../../Common/PageLoader";
import { NormalTable } from "../../../Common/Tables/NormalTable";
import { TaskView } from "../../../Component/TaskView";
import { get } from "../../../Model/Network/Config/Axios";
import { NormalButton } from "../../../Common/Button/NormalButton";
import AddAttendanceModal from "./AddAttendanceModal";
import { useProject } from "../../../Hooks/ProjectSelect";
import { SearchProject } from "../../../Common/Search/SearchProject";
import { toast } from "react-hot-toast";
import { BoxWrapper } from "../../../Common/Wrapper/BoxWrapper";
import { FInput } from "../../../Common/FInput";
import { calculateNewValue } from "@testing-library/user-event/dist/utils";



const Today = () => {

    const [loading,setLoading] = useState(true);
    const [buttonLoading,setButtonLoading] = useState(false);
    const [items,setItems] = useState([]);
    const [showAddAttendanceModal, setShowAddAttendanceModal] = useState(false);
    const PROJECT_REF = useRef();

    useEffect(()=>{
       setLoading(false);
    },[])

    let getAttendance = (project) => {
        if(!project) {
            toast.error("Please select project");
            return;
        }

        setButtonLoading(true);

        get(`/labour/fetchTodayAttendance/${project.id}`, (e, r) => {
            if (r) {
                setButtonLoading(false);
                setItems(r);
            }

            if (e) {
                setButtonLoading(false);
                toast.error(e.data.message);
            }
        })
    }

    let mapList = (item,index) => {
        let date = new Date(item.date);
        date = date.toLocaleString("en-IN");
        return(
            <TaskList 
                key={index}
                title={item.user} 
                desc={`
                    ${item.resource} |
                    ${item.subType} |
                    ${item.vendor} |
                    ${date}
                `}
                onClick={(e)=>{
                    e.stopPropagation();
                }}
                style={{
                    cursor: "default"
                }}
            />
        )
    }


    if(loading) {
        return(<PageLoader />)
    }

    return(
        <div
            style={{
                overflow: "auto",
                height: "calc(100vh - 110px)",

            }}
        >
            <div style={{
                display: "flex",
                justifyContent: "flex-end",
                paddingTop: "10px",
                paddingRight: "15px"
            }}>
                
                <NormalButton 
                    onClick={() => setShowAddAttendanceModal(true)} 
                    text={"Add Attendance"} 
                    style={{
                        maxWidth: 180,
                        marginTop: 0
                    }}
                />
            </div>
            <div>
                <BoxWrapper>
                    <div style={{}}>
                        <SearchProject 
                            title="" 
                            ref={PROJECT_REF} 
                            placeholder = {"Search Project"}
                        />
                        <NormalButton 
                            onClick={() => getAttendance(PROJECT_REF.current.getValue())} 
                            text="Show Attendance" 
                            loading={buttonLoading} 
                            style={{margin: "10px auto"}}
                        />
                    </div>
                </BoxWrapper>
 
                {
                    items.attendances ?
                        (
                            <BoxWrapper>
                                {
                                    items.attendances.length ? (
                                        <NormalTable 
                                            type="approval" 
                                            calendar={null} 
                                            data={items.attendances} 
                                            mapCard={() => {}} 
                                            mapList={mapList}
                                            view={"list"}
                                        />
                                    ) : "No Records Found"
                                }
                            </BoxWrapper>
                        ) : null
                }
            </div>
            {
                showAddAttendanceModal
                    ? <AddAttendanceModal
                        close={() => setShowAddAttendanceModal(false)}
                      />
                    : null
            }
        </div>
    )
}

export default Today;