import Props from 'prop-types';

const NameLayout  = ({title}) => {

    return(
        <div style={{
            height:40,
            width:40,
            border:'2px solid var(--border-color)',
            borderRadius:'50%',
            fontWeight:'700'
        }} className="flex flex-v-center flex-h-center">
            {title.slice(0,1)}
        </div>
    )
}

NameLayout.defaultProps = {
    title:"A"
}

NameLayout.propsTypes = {
    title:Props.any.isRequired
}

export {NameLayout}