import { useEffect, useState } from "react";
import { TaskCard, TaskList } from "../../../Common/CardLayout/TaskCard";
import { FilterOutline } from "../../../Common/Filter/FilterOutline";
import { NormalTable } from "../../../Common/Tables/NormalTable";
import { get } from "../../../Model/Network/Config/Axios";
import Moment from 'moment';
import { PageLoader } from "../../../Common/PageLoader";
import { ManagerView } from "../../../Component/ManagerView";



const Past = () => {

    const [items,setItems] = useState([]);
    const [loading,setLoading] = useState(true);
    const [show,setShow] = useState(false);
    const [calendar,setCalendar] = useState([]);


    useEffect(()=>{
        INIT();
    },[])

    let INIT = () => {
        get(`/approvals/allApprovals`,(e,r)=>{
            if(r){
                let tasks = [];
                Object.keys(r.taskApprovals).forEach(val=>{
                    let filtered = r.taskApprovals[val].filter(val=>val.status!=='PENDING')
                    tasks.push(...filtered)
                })
                let cItems = [];
                tasks.forEach(val=>{
                    cItems.push({
                        start:Moment(val.approvalFloatedAt).format('YYYY-MM-DD'),
                        title:val.taskMappingName,
                        id:val.approvalId,
                        backgroundColor: 'var(--success-color)',
                        borderColor: 'var(--success-color)',
                        textColor:'#fff'
                    })
                })
                setCalendar(cItems);
                setItems(tasks);
                setLoading(false);
            } 
        })
    }


    let mapCard = (item,index) => {
        return(
            <TaskCard 
                size="M" 
                key={index} 
                title={item.taskMappingName} 
                desc={`Submitted By : ${item.responsiblePersonName}`}
                onClick={(e)=>{
                    e.stopPropagation();
                    setShow(item)
                }}
            >
                <p style={{fontSize:14,marginTop:5,color:'var(--text-light-color)'}}>{Moment(item.approvalFloatedAt).format('DD-MM-YYYY')}</p>
            </TaskCard>
        )
    }

    let mapList = (item,index) => {
        return(
            <TaskList 
                title={item.taskMappingName} 
                key={index} 
                desc={item.responsiblePersonName}
                onClick={(e)=>{
                    e.stopPropagation();
                    setShow(item)
                }}
                
            >
                <p style={{fontSize:14,marginTop:5,color:'var(--text-light-color)'}}>{Moment(item.approvalFloatedAt).format('DD-MM-YYYY')}</p>

            </TaskList>
        )
    }

    if(loading) {
        return <PageLoader />
    }

    return(
        <>
            <FilterOutline />
            <div style={{height:'calc(100vh)',overflowY:'auto'}}>
                <NormalTable calendar={calendar} data={items} mapCard={mapCard} mapList={mapList}/>
            </div>
            {
                show?
                <ManagerView item={show} close={()=>{setShow(false)}}/>

                : null
            }
        </>
    )
}

export default Past;