import { useRef, useState } from "react"
import toast from "react-hot-toast";
import { Attachment } from "../../Common/Attachment";
import { NormalButton } from "../../Common/Button/NormalButton";
import { FInput } from "../../Common/FInput";
import { Tab } from "../../Common/Tabs";
import { showToast } from "../../Common/Toast";
import { post } from "../../Model/Network/Config/Axios";



const UpdateTask = ({details,init,finish}) => {
    console.log(details);

    

    const [active,setActive] = useState(!details.workStarted ?'start': details.isPaused?'update':'finish');
    const [buttonLoading,setButtonLoading] = useState(false);
    let REMARKS = useRef(),ATTACHMENT=useRef();

    let submit = (type) =>  {
        let workDesc = REMARKS.current.value;
        let photos = ATTACHMENT.current.getValue();

        if(!photos.length) {
            toast.error("PLease select atleast 1 photo");
            return;
        }
        
        if(!workDesc) {
            toast.error("Please enter the work description");
            return;
        }

        setButtonLoading(true);


        let isFinish = type!=='finish';

        if((details.isPaused && isFinish) || (type==='start')) {
            post(`/task/startTask`,{projectId:details.projectId,taskMappingId:details.id},(e,r)=>{
                if(r) {
                    let data = {
                        photo:photos[0],
                        workDesc,
                        photos,
                        selfi:null,
                        video:null,
                        taskMappingId:details.id
                    }
                    post(`/task/submitChecklist`,data,(e,r)=>{
                        if(r) {
                            setButtonLoading(false);
                            toast.success(type==='start'?"Task Start Successfully":"Task Updated Successfully");
                            REMARKS.current.value = '';
                            ATTACHMENT.current.setNullRef();
                            ATTACHMENT.current.setValue([]);
                            init();
                        } else {
                            toast.error(e.data.message)
                            setButtonLoading(false);
                        }
                    })
                } else {
                    setButtonLoading(false);
                    toast.error(e.data.message);
                }
            })
        } else {

            let data = {
                photo:photos[0],
                workDesc,
                photos,
                selfi:null,
                video:null,
                taskMappingId:details.id,
                onWorkDone:true
            }

            if(!details.workStarted) {
                post(`task/startTask`,{projectId:details.projectId,taskMappingId:details.id},(e,r)=>{
                    if(r) {
                        post(`/task/submitChecklist`,data,(err,res)=>{
                            if(res) {
                                post(`/task/markDone`,{projectId:details.projectId,taskMappingId:details.id},(e,res1)=>{
                                    if(res1) {
                                        setButtonLoading(false);
                                        toast.success("Task Finish Successfully");
                                        REMARKS.current.value = '';
                                        ATTACHMENT.current.setNullRef();
                                        ATTACHMENT.current.setValue([]);
                                        init();
                                        finish();
                                    } else {
                                        toast.error(e.data.message)
                                        setButtonLoading(false);
                                    }
                                })
                            }
                        })
                    } else {
                        showToast({
                            type:'error',
                            message:e.data.message
                        })
                    }
                })

            } else {

                post(`/task/submitChecklist`,data,(e,r)=>{
                    if(r) {
                        post(`/task/markDone`,{projectId:details.projectId,taskMappingId:details.id},(e,r)=>{
                            if(r) {
                                setButtonLoading(false);
                                toast.success("Task Finish Successfully");
                                REMARKS.current.value = '';
                                ATTACHMENT.current.setNullRef();
                                ATTACHMENT.current.setValue([]);
                                init();
                                finish();
                            } else {
                                toast.error(e.data.message)
                                setButtonLoading(false);
                            }
                        })
                    } else {
                        setButtonLoading(false);
                    }
                })
            }
           
          
          


        }
       
    }

    
    let items = [];

    if(!details.workStarted) {
        items.push( {  name:'Start Task',
        active:active==='start',
        onClick:()=>setActive('start')
        })
    }

    if(details.isPaused) {
        items.push(
        {  name:'Update Progress',
        active:active==='update',
        onClick:()=>setActive('update')
        }
        )
    }
    items.push({
        name:'Finish Task',
        active:active==='finish',
        onClick:()=>setActive('finish')
    })

    return(
        <div style={{flex:1}}>
            {
                details.workDone ?
                <></>:
                <>
                    <Tab items={items} />

                    <div style={{marginTop:30}}>
                        <Attachment ref={ATTACHMENT} />
                        <FInput title="Remarks" ref={REMARKS} />
                        <NormalButton loading={buttonLoading} onClick={()=>{
                            if(active==='update') {
                                    submit('update')
                             } else if(active === 'finish') {
                            submit('finish')
                             } else {
                                    submit('start')
                            }
                    }} text={active==='update'?"Update":active==='start'?"Start":"Finish"} />
                      </div>
                </>
            }
           
        </div>
    )
}

export default UpdateTask