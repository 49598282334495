import {  forwardRef, useImperativeHandle, useRef, useState } from 'react';
import axios from 'axios';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css'
import { showToast } from '../Toast/index';
import { pdf } from '../Images';
import { get } from '../../Utils/Crypto';


const Attachment = forwardRef((props,ref) => {

    const [files,setFiles] = useState(props.files?props.files:[]);
    let Title = useRef(),file=useRef();
    let token = get('token')



    useImperativeHandle(ref,()=>({
        getValue:()=>{
            return files
        },
        setValue:(value)=>{
            setFiles(value)
        },
        setNullRef:()=>{
            file.current.value=null
        }
    }))
  

    let onChange = () => {
        let fileValue = file.current.files[0];
        if(fileValue) {
            const formData = new FormData();
            formData.append('file', fileValue);

            axios({
                method: 'post',
                url: `https://api.buildrun.app/main/employee/auth/upload`,
                data: formData,
                field: 'file',
                type: 'multipart',
                headers: {
                    Authorization:`Bearer ${token}`
                }
            })
                .then(res => {
                    if (res.data.status === 'success') {
                        let allFiles = files;
                        if(props.addTitle) {
                            Title.current.value=''
                            allFiles = [...files,{url:res.data.url,title:Title.current.value}]
                        } else {
                            allFiles = [...files,res.data.url]
                        }
                        setFiles(allFiles);
                        showToast({
                            type: 'success',
                            message: 'file upoad successfully'
                        })
                    }

                })
                .catch(res => {
                    showToast({
                        type: 'error',
                        message: res.message
                    })
                })

        } else {
                showToast({
                    type:'error',
                    message:"Please select the valid file"
                })
        }
    }


    let deletes = (item) => {
        let allFiles = files;
        allFiles = allFiles.filter(val=>val !== item);
        setFiles(allFiles);
    }

   let mapItem = (item,index) => {
        let singleValue =  props.addTitle ? item.url:item;
        return(
            <div key={index}>
                {
                    singleValue.toLowerCase().includes('pdf') ?
                    <div style={{marginRight:12}}>
                     <a href={singleValue} rel="noreferrer" target="_blank">
                         <img src={pdf}  alt=""
                             style ={{
                             height:100,
                             width:100,
                             cursor:'pointer',
                             marginRight:12,
                             borderRadius:16
                             }}
                             key={index} />
                      </a>
                      <p onClick={()=>deletes(item)} style={{display:'block',textAlign:'right',fontSize:12,fontWeight:600,color:'red',cursor:'pointer'}}>Delete</p>
                     </div>
                    : 
                    <div style={{marginRight:12}}>
                    <Zoom>
                    <img
                      alt="buildrun"
                      src={singleValue}
                      style={{
                          height:100,
                          width:100,
                          borderRadius:8,
                          marginLeft:12,
                      }}
                    />
                  </Zoom>
                  <p onClick={()=>deletes(item)} style={{display:'block',textAlign:'right',fontSize:12,fontWeight:600,color:'red',cursor:'pointer'}}>Delete</p>
                  </div>
                }
            </div>
        )
    }


    return(
            <div style={{marginBottom:15}}>
                <p style={{
                      fontSize: 14,
                      color: 'var(--title-color)',
                      marginTop: 15,
                      marginBottom:10
                }}>Attachment :</p>
                <div style ={{
                    display:'flex',
                    flexWrap:'wrap',
                    flex:1
                }}>
                    <div style ={{
                         height:100,
                         border:'2px dashed var(--border-color)',
                         width:100,
                         borderRadius:16,
                        flexDirection:'column',
                        cursor:'pointer',
                        }} className="flex flex-v-center flex-h-center" onClick={()=>{
                            file.current.click();
                        }}>
                           <p style={{fontSize:22,paddingBottom:2,fontWeight:'600',color:'#777',border:'2px solid var(--border-color)',height:25,width:25,borderRadius:32,display:'flex',justifyContent:'center',alignItems:'center'}}>+</p>
                    </div>
                    {files.map(mapItem)}
                </div>
                <input 
                        type="file"
                        ref={file} 
                        style={{
                        border:'1px solid var(--border-color)',
                        flex:1,
                        padding:'10px',
                        width:'100%',
                        boxSizing:'border-box',
                        display:'none'
                        }} 
                    
                        onChange={onChange}
                        />
            </div>
        )
})


Attachment.defaultProps = {
    files:[]
}


export {Attachment}


