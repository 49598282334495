import { useRef, useState } from "react";
import toast from "react-hot-toast";
import { RightModal } from "../../../../Common/Modal/RightModal"
import { SearchEmployee } from "../../../../Common/Search/SearchEmployee";
import { SearchProject } from "../../../../Common/Search/SearchProject";
import { SearchTask } from "../../../../Common/Search/SearchTask";
import { post } from "../../../../Model/Network/Config/Axios";
import { SearchWbsItem } from "../../../../Common/Search/SearchWbsItem";
import { SearchVendor } from "../../../../Common/Search/SearchVendor";
import Dpr from "./Dpr";
import { NormalButton } from "../../../../Common/Button/NormalButton";
import { useNavigate } from "react-router-dom";

const ViewDPR = (props) => {

    const [buttonLoading, setButtonLoading] = useState(false);
    const navigate = useNavigate();

    let PROJECT = useRef(),
        WBSITEM = useRef(),
        TASK = useRef(),
        VENDOR = useRef(),
        EMPLOYEE = useRef();


        let generateReport = () =>{
            let projectId = PROJECT.current.getValue()?PROJECT.current.getValue().id:undefined;
            if(!projectId) {
                toast.error("Please select the project");
                return;
            }
            let task = TASK.current.getValue();
            let wbsId = WBSITEM.current.getValue();
            let employee = EMPLOYEE.current.getValue();
            let vendor = VENDOR.current.getValue();
            let filters=[];
            if(wbsId) {
                filters.push({ key:'WBS', val:wbsId.wbsItemName})
            }
    
            if(task) {
                filters.push({ key: "TASK", val: task})
            }
    
            if(vendor) {
                filters.push({ key: "VENDOR", val: vendor})
            }
    
            if(employee) {
                filters.push({ key: "RESPONSIBLE", val: employee})
            }
    
            setButtonLoading(true)
            post(`/misc/dprData/${projectId}`, {orFilters: filters}, (e, r) => {
                if(r) {
                    setButtonLoading(false);
                    navigate("/app/home/dpr", { state: r })
                }
                else if (e) {
                    toast.error(`Error: ${e}`);
                }
            })
        }

    return ( 
            <RightModal 
                close={props.close}
                title="Create Adhoc Task">
                <SearchProject ref={PROJECT} title="Search Project" />
                <SearchTask ref={TASK} title="Search Task (Optional)" />
                <SearchWbsItem projectId={PROJECT}  ref={WBSITEM} title="Search Wbs Item (Optional)" />
                <SearchEmployee ref={EMPLOYEE} title="Search Responsible Person (Optional)" />
                <SearchVendor ref={VENDOR} url="/misc/searchVendor" title="Search Vendor (optional)" />
                <NormalButton loading={buttonLoading} onClick={generateReport} text="Search" />
            </RightModal>
        )
}

export default ViewDPR;
