import { forwardRef } from "react";
import Props from 'prop-types';
import './index.css';

const FInput = forwardRef((props,ref)=>{

    return(
        <div style={{marginBottom:20}}>
             {props.title?<p style={{fontSize:14,marginBottom:8}}>{props.title} : </p>:null}
            <input disabled={props.disabled} onChange={(e)=>{
                if(props.onChange) {
                    props.onChange(e.target.value)
                }
            }} type={props.type?props.type:'text'} ref={ref} className="finput" placeholder={props.placeholder?props.placeholder:props.title}  />
        </div>
    )
})


FInput.propTypes= {
    title:Props.string.isRequired,
    placeholder:Props.string,
    type:Props.string

}

FInput.defaultProps= {
    disabled:false
}



export {FInput}