import './index.css';

const NormalLoadingButton = ({style}) => {

    return(
        <div className="button" style={{...style}}>
                <div className="lds-ellipsis">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
        </div>
    )
}



export {NormalLoadingButton}