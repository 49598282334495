import Props from 'prop-types';
import { NameLayout } from './NameLayout';


const TaskCard = (props) => {

    let width = 100/props.layout;

    let height = 80;

    if(props.size === 'M') {
        height=120
    }

    return(
        <div style={{width:`${width}%`}}>
            <div onClick={props.onClick?props.onClick:()=>{}} className="box-wrapper-shadow" style={{background:'var(--white-color)',border:'1px solid var(--border-color)',position:'relative',cursor:'pointer',height,borderRadius:12,width:'calc(100% - 16px)',margin:12,marginBottom:20,padding:16}}>
                <div style={{display:'flex',alignItems:'center',height:'100%'}}>
                    <NameLayout title={props.title} />
                    <div style={{marginLeft:15,flex:1,WebkitLineClamp:2,textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:"hidden"}}>
                        <p style={{fontWeight:500,fontSize:16}}>{props.title}</p>
                        <p style={{fontWeight:'400',marginTop:5,fontSize:14,color:'var(--text-light-color)'}}>{props.desc}</p>
                        {props.children}

                    </div>
                </div>
                {props.chip?
                <div className="flex flex-v-center flex-h-center" style={{position:'absolute',borderTopLeftRadius:8,borderTopRightRadius:8,left:10,background:'red',padding:'0px 10px',height:20,top:-20,...props.backChip}}>
                    <p style={{fontSize:13,fontWeight:'600',color:"#fff"}}>{props.chip}</p>
                </div>
                :null}
            </div>
        </div>
    )
}


TaskCard.defaultProps = {
    layout:4,
    size:'S'
}

TaskCard.propsTypes={
    layout:Props.any,
    title:'AA'
}




const TaskList = (props) => {

    let height = 75;

    let style = props.style ? props.style : {};
    return(
        <div style={{width:`${100}%`}}>
            <div onClick={props.onClick?props.onClick:()=>{}} className="box-wrapper-shadow" style={{background:'var(--white-color)',flex:1,borderTop:'1px solid var(--border-color)',position:'relative',cursor: style.cursor ? style.cursor : 'pointer',display:'flex',alignItems:'center',height,padding:16}}>
                <div style={{display:'flex',alignItems:'center',height:'100%',flex:1}}>
                    <NameLayout title={props.title} />
                    <div style={{marginLeft:15,flex:1,WebkitLineClamp:2,textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:"hidden"}}>
                        <p style={{fontWeight:500,fontSize:16}}>{props.title}</p>
                        <p style={{fontWeight:'400',marginTop:5,fontSize:14,color:'var(--text-light-color)'}}>{props.desc}</p>
                    </div>
                </div>
                {props.chip?
                <div className="flex flex-v-center flex-h-center" style={{borderRadius:4,minWidth:80, background:'red',padding:'15px 10px',height:20,top:-20,...props.backChip}}>
                    <p style={{fontSize:13,fontWeight:'600',color:"#fff"}}>{props.chip}</p>
                </div>
                :null}
                {props.children?
                <div style={{flex:1}}>
                    {props.children}
                </div>
                :null
                }
            </div>
        </div>
    )
}


TaskList.defaultProps = {
    layout:4,
    size:'S'
}

TaskList.propsTypes={
    layout:Props.any,
    title:'AA'
}




export {TaskList,TaskCard};