import Props from 'prop-types';
import { Portal } from '../Portal';
import close from './close.png';


const Modal = (props) => {

        return (
            <Portal>
                {
                    props.show ?
                        <div style={{
                            position: 'fixed',
                            inset: 0,
                            backgroundColor: 'rgba(0,0,0,0.4)',
                            zIndex:100
                        }}>
                            <div style={{
                                margin: "0px auto",
                                marginTop: 40,
                                backgroundColor: '#fff',
                                minHeight: 320,
                                width: 600,
                                borderRadius: 16,
                                padding:25,
                                ...props.style
                            }}>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    marginBottom:20
                                }}>
                                    <div>
                                        <h2 style={{
                                            fontSize: 22,
                                            color: 'var(--text-color)'
                                        }}>{props.title}</h2>
                                        <p style={{
                                            fontSize: 13,
                                            color: 'var(--text-light-color)'
                                        }}>{props.desc}</p>
                                    </div>
                                    <div className="cross-hover" style ={{
                                        height: 40,
                                        width: 40,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        cursor: 'pointer'
                                    }} onClick={props.onClose}>
                                         <img  src={close} alt="" 
                                            style={{
                                             height: 16,
                                             width: 16,
                                             cursor: 'pointer',
                                             backgroundSize: 'cover'
                                            }}  />

                                    </div>
                                </div>
                                    {props.children}
                            </div>
                        </div>
                        : null
                }
            </Portal>
        )
}


Modal.propsTypes = {
    show: Props.bool,
    onClick: Props.func
}


export { Modal }