import Props from 'prop-types';


const TitleHeader = (props) => {
    return(
        <div style={{position:'relative',flex:1,fontSize:22,fontWeight:'600'}}>
            {props.title}
            {props.children}
        </div>
    )
}

TitleHeader.propsTypes = {
    title:Props.string.isRequired
}
export {TitleHeader}