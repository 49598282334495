import { useEffect } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import Pages from '../../Pages/index';
import { configController } from '../../Reducer/Actions/Config';


const Index = (props) => {

    let {authenticated} = useSelector(state=>(state.Config));
    let Navigate = useNavigate();
    let dispatch = useDispatch();

    useEffect(()=>{
        dispatch(configController())
    },[dispatch])
    
    useEffect(()=>{
        if(!authenticated) {
            Navigate('/')
        }
    },[authenticated])
   
    return(
        <Pages  {...props} />
    )
}

export default Index;