import { get } from "../../Utils/Crypto"
import { TableView } from "../Tables/TableView"
import { RefObject } from "../../Utils/Refrence";
import right from './next.png';
import Moment from 'moment';
import { useEffect, useState } from "react";

const FilterOutline = (props) => {


    let [date,setDate] = useState(Moment());
    let CalendarRef = RefObject.getRef();

    useEffect(()=>{ 
        if(CalendarRef) {
            setCurrentDate(CalendarRef.getDate());
        }
    },[CalendarRef])

    let setCurrentDate = (date) => {
        setDate(date)
    }

    let tType = get('table-view');
    
    let type = () => {
        if(tType === 'calendar') {
            return(
                <div style={{padding:'0px 10px',display:'flex',alignItems:'center'}}>
                    <p style={{cursor:"pointer",paddingRight:10}} onClick={()=>{
                        CalendarRef.today();
                        setCurrentDate(CalendarRef.getDate());
                        }}>Today</p>
                    <div style={{borderLeft:'1px solid var(--border-color)',borderRight:'1px solid var(--border-color)',padding:'0px 10px'}}>
                        <img src={right} onClick={()=>{
                            CalendarRef.prev();
                            setCurrentDate(CalendarRef.getDate());
                            }} alt="" style={{height:12,cursor:'pointer',transform:'rotate(180deg)'}} />
                        <img src={right} alt="" onClick={()=>{
                            CalendarRef.next();
                            setCurrentDate(CalendarRef.getDate());
                        }} style={{height:12,cursor:'pointer',marginLeft:10}} />
                    </div>
                    <div style={{padding:'0px 10px'}}>
                        {Moment(date).format('MMM YYYY')}
                    </div>
                </div>
            )
        }
    }

    return(
        <div style={{
            height:50,
            borderBottom:'1px solid var(--border-color)',
            background:"#fff",
            borderLeft:'1px solid var(--border-color)',
            display:'flex',
            justifyContent:'space-between',
            alignItems:'center',
            padding:'0px 10px'
        }}>
            <div style={{display:'flex'}}>
                <div style={{borderRight:'1px solid var(--border-color)',padding:'0px 10px'}}></div>
                {type()}
            </div>
            <div>
                {props.showFilter ?<TableView  /> : null}
            </div>
        </div>
    )
}


FilterOutline.defaultProps  = {
    showFilter:true
}


export  {FilterOutline}