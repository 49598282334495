import { useEffect, useState } from "react";
import Moment from 'moment';
import { TaskCard, TaskList } from "../../../Common/CardLayout/TaskCard";
import { FilterOutline } from "../../../Common/Filter/FilterOutline";
import { PageLoader } from "../../../Common/PageLoader";
import { NormalTable } from "../../../Common/Tables/NormalTable";
import { TaskView } from "../../../Component/TaskView";
import { get } from "../../../Model/Network/Config/Axios";



const Index = () => {

    const [loading,setLoading] = useState(true);
    const [items,setItems] = useState([]);
    const [show,setShow] = useState(false);
    const [calendar,setCalendar] = useState([]);


    useEffect(()=>{
        get(`approvals/ongoingTasks`,(e,r)=>{
            if(r) {
                let list = [];
                Object.keys(r.tasks).forEach(val=>{
                    list.push(...r.tasks[val])
                })
                let cItems = [];
                list.forEach(val=>{
                    cItems.push({
                        start:Moment(val.estimatedStartDate).format('YYYY-MM-DD'),
                        end:Moment(val.estimatedCompletionDate).format('YYYY-MM-DD'),
                        title:val.wbsItemTaskName,
                        id:val.id,
                        backgroundColor: val.critical?'var(--danger-color)': val.isSnag?'var(--other-color)': val.isPaused?'var(--warning-color)' :'#4FC9DA',
                        borderColor: val.critical?'var(--danger-color)': val.isSnag?'var(--other-color)': val.isPaused?'var(--warning-color)' :'#4FC9DA',
                        textColor:'#fff'
                    })
                })
                setCalendar(cItems);
                setItems(list)
                setLoading(false);
            }
        })
    },[])


    let mapCard = (item,index) => {
        return(
            <TaskCard 
                size="M" 
                key={index} 
                title={item.task.name} 
                chip={item.isSnag ? 'Snag' : item.isPaused ? 'Paused' : item.workStarted ? 'Live' : 'Pending'}
                backChip={item.isSnag ? {background:'var(--other-color)'} : item.isPaused ? {background:'var(--warning-color)'} : item.workStarted ? {background:'var(--success-color)'} : {background:'var(--theme-main-color)'}}
                desc={item.wbsItemTaskName}
                onClick={(e)=>{
                    e.stopPropagation();
                    setShow(item)
                }}
            >

            </TaskCard>
        )
    }

    let mapList = (item,index) => {
        return(
            <TaskList 
                title={item.task.name} 
                key={index} 
                chip={item.isSnag ? 'Snag' : item.isPaused ? 'Paused' : item.workStarted ? 'Live' : 'Pending'}
                backChip={item.isSnag ? {background:'var(--other-color)'} : item.isPaused ? {background:'var(--warning-color)'} : item.workStarted ? {background:'var(--success-color)'} : {background:'var(--theme-main-color)'}}
                desc={item.wbsItemTaskName}
                onClick={(e)=>{
                    e.stopPropagation();
                    setShow(item)
                }}
                
            />
        )
    }


    if(loading) {
        return(<PageLoader />)
    }


    return(
        <>
            <FilterOutline />
            <div style={{height:'calc(100vh - 90px)',overflowY:'auto'}}>
                <NormalTable calendar={calendar} data={items} mapCard={mapCard} mapList={mapList}/>
            </div>
            {
                show?
                <TaskView 
                    close={()=>setShow(false)}
                    item={show}
                    isUpdate={false}
                />
                :null
            }
        </>
    )
}

export default Index;