import { useEffect, useState } from "react";
import {useNavigate} from 'react-router-dom';
import Moment from 'moment';
import { get } from "../../Model/Network/Config/Axios";
import { ProjectCardLayout } from "../CardLayout/CardLayout1";
import { Chip } from "../CardLayout/Chip";
import { downarrow } from "../Images";
import { CenterModal } from "../Modal/CenterModal";
import './index.css';
import { set} from "../../Utils/Crypto";
import { useProject } from '../../Hooks/ProjectSelect'


const Project = () => {

    const project = useProject()
    const [name,setName] = useState(project?project:{name:'All Project'});
    const [list,setList] = useState([]);
    const [open,setOpen] = useState(false);
    let Navigate = useNavigate();


    useEffect(()=>{
        get(`task/allProjects`,(e,r)=>{
            if(r) {
                let items= [];
                items.push({
                    name:"All Project",
                    description:'NA',
                    city:'NA',
                    plannedFinishDate:undefined,
                    estimatedFinishDate:undefined
                })
                items.push(...r.projects)
                setList(items)
            }
        })
    },[])


    let mapProject = (item,index) => {
        let active= name.name===item.name
        return(
            <ProjectCardLayout 
                key={index} 
                title={item.name} 
                layoutNumber={5}
                description={`${item.description} , ${item.city}`}
                onClick={()=>{
                    setName(item.name)
                    set('project',item);
                    Navigate('/app/home');
                    setOpen(false)
                    Navigate(0)
                }}
            >
                <div style={{position:'absolute',right:10,top:-40}}>
                    {
                        active ?
                        <Chip text="Active" background="var(--button-color)" style={{color:'#fff',fontWeight:600}} />
                        :<Chip text="Select"  />
                    }
                </div>
                <Chip text={`Planned Finished Date ${item.plannedFinishDate? Moment(item.plannedFinishDate).format('DD-MM-YYYY'):'NA'}`}/>
                <Chip text={`Estimated Finished Date ${item.estimatedFinishDate? Moment(item.estimatedFinishDate).format('DD-MM-YYYY'):'NA'}`}/>
            </ProjectCardLayout>
        )
    }


    return(
        <>
        <div className="project" onClick={()=>{setOpen(true)}}>
            <p>{name.name}</p>
            <img src={downarrow} alt="" style={{height:20,width:20}} />
           
        </div>
         {
            open ?
            <CenterModal 
                title="List of All Projects"
                close={()=>{
                    setOpen(false)
                }}>
                <div style={{display:'flex',flexWrap:'wrap'}}>
                    {list.map(mapProject)}
                </div>
            </CenterModal>
            : null
        }
        </>
    )
}

export {Project}