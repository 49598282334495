import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react"
import { get } from "../../../Model/Network/Config/Axios";
import { FInput } from "../../FInput"


const SearchTask = forwardRef((props,ref) =>{


    const [text,setText] = useState('');
    const [task,setTask] = useState([]);
    const [item,setItem] = useState(undefined);
    let NAME = useRef();


    useImperativeHandle(ref,()=>({
        getValue:()=>{
            return item
        }
    }))

    useEffect(()=>{
        if(text.length>0) {
            get(`task/adhocTask/task.search`, (e, r) => {
                if (r) {
                   setTask(r.tasks)
                }
            }, {
                q: text
            })
        } else {
            setTask([])
        }

    },[text])



    return(
        <div style={{position:'relative'}}>
            <FInput ref={NAME} title={props.title} onChange={setText} />
            {
                task.length>0 ?
                <div className="suggestion-modal" style={{marginTop:21}}>
                    {task.map((val,index)=>(
                    <div key={index} className="suggestion-modal-item" onClick={()=>{
                        setItem(val);
                        NAME.current.value = val.taskName;
                        setTask([]);
                        }}>
                        <div>
                            <p>{val.taskName}</p>
                            <p style={{fontSize:12,color:'var(--text-light-color)'}}>{val.taskType}</p>
                        </div>
                    </div>))}
                </div>:
                null
            }
        </div>
    )
})

export {SearchTask}