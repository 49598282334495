import Props from 'prop-types';

const Chip = (props) => {

    return(
        <div style={{marginTop:8}}>
            <span style={{background:props.background?props.background:'var(--background-color)',letterSpacing:0.5,fontSize:12,borderRadius:8,padding:'2px 15px',...props.style}}>
                 {props.text} <br></br>
             </span>
        </div>
    )
}

Chip.propsTypes = {
    text:Props.string.isRequired
}

export {Chip}