import Props from 'prop-types';
import { NameLayout } from './NameLayout';


const Card = (props) => {

    let width = 100/props.layout;

    let height = 80;

    if(props.vertical === 'M') {
        height=120
    }

    return(
        <div style={{width:`${width}%`}}>
            <div onClick={props.onClick?()=>props.onClick():()=>{}} className="box-wrapper-shadow" style={{background:'var(--white-color)',cursor:'pointer',height,borderRadius:12,width:'calc(100% - 16px)',margin:12,padding:16}}>
                <div style={{display:'flex',alignItems:'center',height:'100%'}}>
                    <NameLayout title={props.title} />
                    <div style={{marginLeft:15,flex:1}}>
                        <p style={{fontWeight:500,fontSize:16}}>{props.title}</p>
                        <p style={{fontWeight:'400',fontSize:14,color:'var(--text-light-color)'}}>{props.desc}</p>
                    </div>
                </div>
                {props.children}
            </div>
        </div>
    )
}


Card.defaultProps = {
    layout:4,
    vertical:'S'
}

Card.propsTypes={
    layout:Props.any,
    title:'AA'
}


export {Card};