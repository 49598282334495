import {Routes,Route} from 'react-router-dom';
import { Header } from '../../Common/Header';
import { Navigation } from '../../Common/TopNavigation';
import Live from './Live'
import Past from './Past';
import Upcoming from './Upcoming';


const Index = () => {

    return(
        <>
            <Header>
                <Navigation items={[{name:'Live',path:"/app/task"},{name:'Upcoming',path:'/app/task/upcoming'},{name:'Past',path:'/app/task/past'}]} />
            </Header>
            <Routes>
                <Route path="/" element={<Live />} exact />
                <Route path="/upcoming" element={<Upcoming />} exact/>
                <Route path="/past" element={<Past />} exact />
            </Routes>
        </>
    )
}

export default Index;