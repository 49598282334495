import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react"
import { get } from "../../../Model/Network/Config/Axios";
import { FInput } from "../../FInput"


const SearchHinderance = forwardRef((props,ref) =>{


    const [text,setText] = useState('');
    const [task,setTask] = useState([]);
    const [item,setItem] = useState(undefined);
    let NAME = useRef();


    useImperativeHandle(ref,()=>({
        getValue:()=>{
            return item
        },
        setValue:(value)=>{
            NAME.current.value=value
        }
    }))

    useEffect(()=>{
        if(text.length>0) {
            get(`/misc/searchHindrance`, (e, r) => {
                if (r) {
                   setTask(r.hindrances)
                }
            }, {
                q: text
            })
        } else {
            setTask([])
        }

    },[text])



    return(
        <div style={{position:'relative'}}>
            <FInput ref={NAME} title={props.title} onChange={setText} />
            {
                task.length>0 ?
                <div className="suggestion-modal" style={{marginTop:21}}>
                    {task.map((val,index)=>(
                    <div key={index} className="suggestion-modal-item" onClick={()=>{
                        setItem(val);
                        NAME.current.value = val.title;
                        setTask([]);
                        }}>
                        <div>
                            <p>{val.title}</p>
                            <p style={{fontSize:12,color:'var(--text-light-color)'}}>{val.description}</p>
                        </div>
                    </div>))}
                </div>:
                null
            }
        </div>
    )
})

export {SearchHinderance}