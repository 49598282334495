import { useEffect, useState } from 'react';
import { PageLoader } from '../../../Common/PageLoader';
import { BoxWrapper } from '../../../Common/Wrapper/BoxWrapper';
import { get } from '../../../Model/Network/Config/Axios';


const Index = () => {

    const [details,setDetails] = useState('');
    const [loading,setLoading] = useState(true);

    useEffect(()=>{ 
        INIT()
    },[])

    let INIT = () => {
        get(`/auth/config`,(e,r)=>{
            if(r) {
                setDetails(r);
                setLoading(false);
            }
        })
    }


    if(loading) {
        return <PageLoader />
    }

    return(
        <div style={{padding:20}}>
            <BoxWrapper  style={{height:360,borderRadius:4,width:'100%'}} innerStyle={{display:'flex',alignItems:'center'}}>
                <div style={{height:200}}>
                    <img alt="" src={require('./profile.png')}  style={{height:260,width:260,objectFit:'contain',borderRadius:'50%'}} />
                </div>
                <div style={{marginLeft:40,marginTop:0,flex:1,display:'flex'}}>
                    <div style={{flex:1,textAlign:'center'}}>
                        <h2>Details</h2>
                        <div style={{marginTop:60}}>
                            <p style={{fontSize:18}}>{details.name}</p>
                            <p style={{fontSize:14,color:'var(--text-light-color)'}}>{details.email}</p>

                        </div>
                    </div>
                    <div style={{flex:1,textAlign:'center',borderRight:'1px dashed var(--border-color)',borderLeft:'1px dashed var(--border-color)'}}>
                        <h2>Organisation Details</h2>
                        <div style={{marginTop:60}}>
                            <p style={{fontSize:18}}>{details.organization.name}</p>
                            <p style={{fontSize:14,color:'var(--text-light-color)'}}>{details.organization.country}</p>

                        </div>
                    </div>
                    <div style={{flex:1,textAlign:'center'}}>
                        <h2>Other Details</h2>
                    </div>
                 </div>
            </BoxWrapper>
           
        </div>
    )
}

export default Index;