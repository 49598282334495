import { useEffect, useState } from "react";
import Moment from 'moment';
import { TaskCard, TaskList } from "../../../Common/CardLayout/TaskCard";
import { FilterOutline } from "../../../Common/Filter/FilterOutline";
import { PageLoader } from "../../../Common/PageLoader";
import { NormalTable } from "../../../Common/Tables/NormalTable";
import { TaskView } from "../../../Component/TaskView";
import { useProject } from "../../../Hooks/ProjectSelect";
import {get} from '../../../Model/Network/Config/Axios'


const Index = () => {

    const [loading,setLoading] = useState(true);
    const [items,setItems] = useState([]);
    const [show,setShow] = useState(false);
    const [gantt,setGantt] = useState([]);
    const [calendar,setCalendar] = useState([]);

    const project = useProject();

    useEffect(()=>{
        INIT();
    },[])

    let INIT = () => {
        let url = `/task/completedTasks`
        if(project) {
            url = `/task/completedTasks?projectId=${project}`
        }
        get(`${url}`,(e,r)=>{
            if(r) {
                let list = [];
                Object.keys(r.tasks).forEach(val=>{
                    list.push(...r.tasks[val])
                })

                let gItems = [];
                list.forEach(val=>{
                    gItems.push({
                        start: Moment(val.plannedStartDate).toDate(),
                        end: Moment(val.plannedCompletionDate).toDate(),
                        name: val.task.name,
                        id: val.id,
                        type:'task',
                        progress: 50,
                        isDisabled: true,
                        styles: { progressColor: '#ffbb54', progressSelectedColor: '#ff9e0d' },
                        dependencies:`${val.wbsItemMapping.id}`
                    
                    })
                })

                let cItems = [];
                list.forEach(val=>{
                    cItems.push({
                        start:Moment(val.estimatedStartDate).format('YYYY-MM-DD'),
                        end:Moment(val.estimatedCompletionDate).format('YYYY-MM-DD'),
                        title:val.wbsItemTaskName,
                        id:val.id,
                        backgroundColor: val.critical?'var(--danger-color)': val.isSnag?'var(--other-color)': val.isPaused?'var(--warning-color)' :'var(--success-color)',
                        borderColor: val.critical?'var(--danger-color)': val.isSnag?'var(--other-color)': val.isPaused?'var(--warning-color)' :'var(--success-color)',
                        textColor:'#fff'
                    })
                })
                setCalendar(cItems)
                setGantt(gItems);
                setItems(list);
                setLoading(false);
            }
        })
    }

    let mapCard = (item,index) => {
        return(
            <TaskCard 
                size="M" 
                key={index} 
                title={item.task.name} 
                desc={item.wbsItemTaskName}
                chip={item.isSnag ? 'Snag' : item.isPaused ? 'Paused' : item.workStarted ? 'Live' : 'Not Started'}
                backChip={item.isSnag ? {background:'var(--other-color)'} : item.isPaused ? {background:'var(--warning-color)'} : item.workStarted ? {background:'var(--success-color)'} : {background:'var(--theme-main-color)'}}
                onClick={(e)=>{
                    e.stopPropagation();
                    setShow(item)
                }}
            >

            </TaskCard>
        )
    }

    let mapList = (item,index) => {
        return(
            <TaskList 
                title={item.task.name} 
                desc={item.wbsItemTaskName}
                key={index} 
                chip={item.isSnag ? 'Snag' : item.isPaused ? 'Paused' : item.workStarted ? 'Live' : 'Not Started'}
                backChip={item.isSnag ? {background:'var(--other-color)'} : item.isPaused ? {background:'var(--warning-color)'} : item.workStarted ? {background:'var(--success-color)'} : {background:'var(--theme-main-color)'}}
                onClick={(e)=>{
                    e.stopPropagation();
                    setShow(item)
                }}
                
            />
        )
    }


    if(loading) {
        return <PageLoader />
    }

    return(
        <>
            <FilterOutline />
            <div style={{height:'calc(100vh - 100px)',overflowY:'auto'}}>
                <NormalTable calendar={calendar} gantt={gantt} data={items} mapCard={mapCard} mapList={mapList}/>
            </div>
            {
                show?
                <TaskView 
                    close={()=>setShow(false)}
                    item={show}
                    isUpdate={false}
                />
                :null
            }
        </>
    )
}

export default Index;