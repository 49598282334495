import { useEffect, useState } from "react";
import { RightModal } from "../../Common/Modal/RightModal";
import { PageLoader } from "../../Common/PageLoader";
import { TopLine } from "../../Common/TopNavigation/TopLine";
import { get } from "../../Model/Network/Config/Axios";
import Details from './Details';
import Timeline from './TimeLine';


const ManagerView = (props) => {

    const [loading,setLoading] = useState(true);
    const [active,setActive] = useState('details');
    const [details,setDetails] = useState({});
    const [checklist,setCheckList] = useState({});
    const [images,setImages] = useState([]);

    let {item} = props;

    useEffect(()=>{
       INIT(item.approvalId)
    },[item.approvalId])

    let INIT = (id) => {
        get(`/approvals/details/${id}`, (e, r) => {
            if (r) {
                get(`/task/checkLists/${r.taskApprovals.taskMappingDetails.taskMappingId}`, (error, res) => {
                    if (res) {
                        let images = [];
                        let checkList = res.checklists;
                        Object.keys(checkList).forEach((val) => {
                            Object.keys(checkList[val]).forEach((item) => {
                                images.push({photo:checkList[val][item].photo,date:checkList[val][item].date})
                                checkList[val][item].photos.forEach(i=>{
                                    images.push({
                                    photo:i,
                                    date:checkList[val][item].date
                                    })
                                })
                            })
                        })
                        images = images.reverse();
                        setDetails(r.taskApprovals);
                        setImages(images);
                        setCheckList(res.checklists);
                        setLoading(false);
                    }
                })
            }

        })
    }
    return(
        <RightModal
            size="L"
            close={props.close}
            title={item.taskMappingName}
        >
             <TopLine
                items={[
                    {
                        name:'Details',
                        active:active==='details',
                        onClick:()=>setActive('details')
                    },
                    {
                        name:"Timeline",
                        onClick:()=>setActive('timeline'),
                        active:active==='timeline'

                    },
                ]}
            />
            {
                loading?
                <PageLoader />
                :
                <div style={{marginTop:40}}>
                    {active==='details'?<Details id={item.approvalId} init={()=>{
                        INIT(item.approvalId)
                        props.init();

                    }} images={images} details={details} />:null}
                    {active==='timeline'?<Timeline timeline={checklist} />:null}
                </div>
            }

        </RightModal>
    )
}

export {ManagerView};