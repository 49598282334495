import { get } from "../Utils/Crypto"



const useProject = () => {
    let project = get('project');
    if(project && project.name === 'All Project') {
        return undefined;
    }
    return project;
}


export {useProject}