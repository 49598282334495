import {Routes,Route} from 'react-router-dom'
import { Header } from '../../Common/Header';
import { Project } from '../../Common/Projects';
import Dpr from './Main/Dpr/Dpr';
import Main from './Main/index';
import Task from './Task/index';

const Home = () => {

    return(
        <>
            <Header>
                <div style={{position:'absolute',right:0,top:12}}>
                    <Project />
                </div>
            </Header>
            <Routes>
                <Route path="/" element={<Main />} exact />
                <Route path="/dpr" element={<Dpr />} exact />
                <Route path="/task/:id" element={<Task />} exact />
             </Routes>
        </>
    )
}

export default Home;
