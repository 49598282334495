import { get } from "../../Utils/Crypto";



const AuthAction = {
    Login: "Auth_Login",
    Config:"Auth_Config",
    Table:"Table_View"
}


const initialStrore = () => {

    let loggedIn = get('loggedin');
    if(loggedIn && loggedIn==='yes')  {
        let project = get('project');
        let tableview = get('table-view')?get('table-view'):'card';

        return {
            authenticated:true,
            project,
            tableview,
        }
    } else {
        return {
            authenticated:false,
            tableview:'card'
        }
    }
}


const Config = (state=initialStrore(),action) => {

    let data = action.data;

    switch(action.type) {
        case AuthAction.Login: 
        return {
            ...state,
            name:data.name,
            authenticated:true,
        }
        case AuthAction.Config:
          return {
              ...state,
              data
          }
        case AuthAction.Table:
            return {
                ...state,
                tableview:data
            }
       
        default:
        return state;
    }

}

export default Config;
export {AuthAction}