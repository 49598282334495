import {Route,Routes} from 'react-router-dom';
import { Header } from '../../Common/Header';
import { Navigation } from '../../Common/TopNavigation';
import Today from './Today/index';
import Past from './Past/index';


const Attendance = () => {

    return(
        <>
            <Header>
                <Navigation items={[
                    {
                        name:'Today',
                        path:"/app/attendance"
                    },
                    {
                        name:'Past',
                        path:'/app/attendance/past'
                    }
                ]} />
            </Header>
           <Routes>
               <Route path="/" element={<Today />} />
               <Route path="/past" element={<Past />} />
           </Routes>
        </>
    )
}

export default Attendance;