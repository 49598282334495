import Props from 'prop-types';
import { close } from '../Images';
import { Portal } from "../Portal";
import './index.css';

const CenterModal = (props) => {

    return(
        <Portal>
            <div className="center-modal-wrapper">
                <div className="center-modal-wrapper-inside">
                    <div className="modal-header">
                        <p style={{fontSize:18,fontWeight:600}}>{props.title}</p>
                        <div className="cross-hover" onClick={props.close}>
                            <img src={close} alt="" style={{height:15,width:15,cursor:'pointer'}} />
                        </div>
                    </div>
                    <div style={{height:'calc(100% - 60px)',overflowY:'auto',padding:'40px 20px'}}>
                        {props.children}
                    </div>
                </div>
            </div>
        </Portal>
    )
}

CenterModal.defaultProps = {
    header:true
}

CenterModal.propsTypes = {
    header:Props.bool,
    title:Props.string.isRequired
}



export {CenterModal}