import Props from 'prop-types';
import {  useRef } from 'react';
import { close } from '../Images';
import { Portal } from "../Portal";
import './index.css';

const RightModal = (props) => {

    let modalRef=useRef();
    
    let width = '600px';
    if(props.size === 'M') {
        width="60%"
    } else if(props.size === 'L') {
        width='70%'
    }

    // let onClickOutSide = (e) => {
    //     if(modalRef.current && !modalRef.current.contains(e.target)) {
    //         props.close()
    //     }
    // }

    // useEffect(()=>{
    //     window.addEventListener('click',onClickOutSide);
    //     return ()=>{
    //         window.removeEventListener('click',onClickOutSide)
    //     }
    // },[])

 

    return(
        <Portal>
            <div className="right-modal-wrapper">
                <div data-aos="fade-left" data-aos-duration="600" ref={modalRef}  className="right-modal-wrapper-inside" style={{width}}>
                    <div className="modal-header" >
                        <p style={{fontSize:18,fontWeight:600}}>{props.title}</p>
                        <div className="cross-hover" onClick={props.close}>
                            <img src={close} alt="" style={{height:15,width:15,cursor:'pointer'}} />
                        </div>
                    </div>
                    <div style={{height:'calc(100% - 60px)',overflowY:'auto',padding:'20px 30px'}}>
                        {props.children}
                    </div>
                </div>
            </div>
        </Portal>
    )
}

RightModal.defaultProps = {
    header:true
}

RightModal.propsTypes = {
    header:Props.bool,
    title:Props.string.isRequired
}



export {RightModal}