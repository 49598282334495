import { useRef, useState } from "react";
import toast from "react-hot-toast";
import { NormalButton } from "../../Common/Button/NormalButton";
import { FInput } from "../../Common/FInput";
import { post } from "../../Model/Network/Config/Axios";


const Index = (props) => {

    const [screen,setScreen] = useState(1);
    const [buttonLoading,setButtonLoading] = useState(false);
    const [email,setEmail] = useState('');
    let EMAIL  = useRef(),OTP = useRef(),PASS = useRef(),CPASS = useRef();

    let screen1 = () => {
        return(
            <>
                <FInput type="Email" ref={EMAIL}  title="Email"/>
                <NormalButton loading={buttonLoading} text="Next" onClick={()=>{
                    let username  = EMAIL.current.value;
                    if(!username) {
                        toast.error("Please enter the email");
                        return;
                    }
                    setButtonLoading(true);
                    post(`auth/sendOtp`,{username},(e,r)=>{
                        if(r) {
                            EMAIL.current.value=''
                            setEmail(username);
                            toast.success("Otp sent successfully to your email id");
                            setButtonLoading(false);
                            setScreen(2);
                        } else {
                            toast.error(e.data.message);
                            setButtonLoading(false);
                        }
                    })
                }} />
            </>
        )
    }

    let screen2 = () => {
        return(
            <>
                <FInput type="text" title="Otp"  ref={OTP}/>
                <FInput type="password" title="Password" ref={PASS} />
                <FInput type="password" title="Confirm Password" ref={CPASS} />
                <NormalButton  text="Reset"
                    onClick={()=>{
                        let otp = OTP.current.value;
                        let password = PASS.current.value;
                        let confirm = CPASS.current.value;
                        if(!otp) {
                            toast.error("Please enter the otp");
                            return
                        }

                        if(password<6) {
                            toast.error("Password length must be greater than or equal to 6 digit")
                            return;
                        }

                        if(password !== confirm) {
                            toast.error("password and confirm password is not matching");
                            return;
                        }

                        let data = {otp,password,confirm,username:email};

                        post(`auth/forgot`,data,(e,r)=>{
                            if(r) {
                                toast.success("Password reset successfully");
                                props.reset();
                            } else {
                                toast.error(e.data.message);
                            }
                        })
                    }}
                />
            </>
        )
    }

    return(
        <div style={{height:"100vh",background:"#f2f2f2"}}>
        <div style={{height:'100vh',display:'flex',alignItems:'center',justifyContent:'center'}}>
            <div style={{
                minHeight:420,
                width:430,
                borderRadius:16,
                padding:25,
                background:"#fff"
            }} className="box-shadow">
                
                <p style={{textAlign:'center',fontWeight:'600',fontSize:28}}>Forgot Password</p>
                <p style={{textAlign:'center',color:'var(--text-light-color)',fontSize:15,marginTop:10}}>Hey, Enter your details to get password in <br></br> to your account</p>

                <div style={{marginTop:70}}>
                    {
                        screen === 1 ?
                        screen1():
                        screen2()
                    }
                </div>
            </div>
        </div>
    </div>
    )
}

export default Index;