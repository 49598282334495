import {Route,Routes} from 'react-router-dom';
import { Header } from '../../Common/Header';
import { Navigation } from '../../Common/TopNavigation';
import Ongoing from './Ongoing/index';
import Pending from './Pending/index';
import Past from './Past/index';


const Index = () => {

    return(
        <>
            <Header>
                <Navigation items={[{name:'Pending',path:"/app/approval"},{name:'Past',path:'/app/approval/past'},{name:'Ongoing Task',path:'/app/approval/ongoing'}]} />
            </Header>
           <Routes>
               <Route path="/" element={<Pending />} />
               <Route path="/past" element={<Past />} />
               <Route path="/ongoing" element={<Ongoing />} />
           </Routes>
        </>
    )
}

export default Index;