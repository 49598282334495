import { useEffect, useState } from "react";
import { RightModal } from "../../Common/Modal/RightModal";
import { PageLoader } from "../../Common/PageLoader";
import { TopLine } from "../../Common/TopNavigation/TopLine";
import { get } from "../../Model/Network/Config/Axios";
import Details from './Details';
import TimeLine from './TimeLine';
import Resource from './Resource';

const TaskView =  (props) => {

    const [active,setActive] = useState('details');
    const [details,setDetails] = useState({});
    const [loading,setLoading] = useState(true);
    const [timeline,setTimeline] = useState({});

    let {item} = props;
    let id = item.id ? item.id:item.taskMappingId


    useEffect(()=>{
        INIT(id);
    },[id])

    let INIT = (id) => {
        get(`task/taskDetails/${id}`, (e, r) => {
            if (r) {
                get(`task/checkLists/${id}`, (e, res) => {
                    if (r) {
                        setDetails(r)
                        setTimeline(res.checklists?res.checklists:{})
                        setLoading(false)
                    }
                })
            }
        })
    }

    return(
        <RightModal
            size="L"
            close={props.close}
            title={item.name?item.name:item.task.name}
        >
            <TopLine
                items={[
                    {
                        name:'Details',
                        active:active==='details',
                        onClick:()=>setActive('details')
                    },
                    {
                        name:"Timeline",
                        onClick:()=>setActive('timeline'),
                        active:active==='timeline'

                    },
                    {
                        name:'Drawing',
                        onClick:()=>setActive('drawing'),
                        active:active==='drawing'
                    },
                    {
                        name:'Resource',
                        onClick:()=>setActive('resource'),
                        active:active==='resource'
                    },
                    {
                        name:'Comment',
                        onClick:()=>setActive('comment'),
                        active:active==='comment'
                    }
                ]}
            />
            {
                loading ?
                <PageLoader />
                :
                <div style={{marginTop:40}}>
                    {active==='details'?<Details isUpdate={props.isUpdate} finish={()=>{INIT(id);props.onINIT()}} init={()=>{INIT(id);props.onUpdate()}} details={details} />:null}
                    {active==='timeline'?<TimeLine timeline={timeline} />:null}
                    {active==='resource'?<Resource taskId={id}  />:null}
                </div>
            }
        </RightModal>
    )
}

TaskView.defaultProps = {
    isUpdate:true
}

export {TaskView};