import { useEffect, useState } from "react";
import Moment from 'moment';
import { TaskCard, TaskList } from "../../../Common/CardLayout/TaskCard";
import { FilterOutline } from "../../../Common/Filter/FilterOutline";
import { PageLoader } from "../../../Common/PageLoader";
import { PaginationTable } from "../../../Common/Tables/PaginationTable";
import { TaskView } from "../../../Component/TaskView";
import { useProject } from "../../../Hooks/ProjectSelect";
import { get } from "../../../Model/Network/Config/Axios";


const Index = () => {

    const [loading,setLoading] = useState(true);
    const [items,setItems] = useState([]);
    const [show,setShow] = useState(false);
    const [calendar] = useState([]);
    const [table,setTable] = useState({total:0,totalPages:0})


    const project = useProject();

    useEffect(()=>{
        INIT();
    },[])

    let INIT = (data) => {

        if(!data) {
            data={page:0}
        }

        data.offset = data.page;
        data.size =  100+100*data.page;

        let url = `/task/upcomingTasks`
        if(project) {
            console.log(project)
            url = `/task/upcomingTasks?projectId=${project.id}`
        }
        

        get(`${url}`,(e,r)=>{
            if(r) {
                let list = [];
                r.items.forEach(val=>{
                    if(val.type==='data') {
                        list.push(...val.data)
                    }
                })

                let gItems=[];

                list.forEach(val=>{
                    gItems.push({
                        start: Moment(val.plannedStartDate).toDate(),
                        end: Moment(val.plannedCompletionDate).toDate(),
                        name: val.task.name,
                        wbsItemName:val.wbsItemMapping.name,
                        id: val.id,
                        type:'task',
                        progress: 50,
                        isDisabled: true,
                        styles: { progressColor: '#ffbb54', progressSelectedColor: '#ff9e0d' },
                        dependencies:`${val.wbsItemMapping.id}`
                    
                    })
                })

                setItems(gItems)
                setTable({
                    total:r.total,
                    totalPages:r.totalPages,
                    page:data.page
                })
                setLoading(false);
            }
        },{...data})
        
    }

    let mapCard = (item,index) => {
        return(
            <TaskCard 
                size="M" 
                key={index} 
                title={item.task?item.task.name:item.name} 
                desc={item.wbsItemName}
                chip={item.isSnag ? 'Snag' : item.isPaused ? 'Paused' : item.workStarted ? 'Live' : 'Not Started'}
                backChip={item.isSnag ? {background:'var(--other-color)'} : item.isPaused ? {background:'var(--warning-color)'} : item.workStarted ? {background:'var(--success-color)'} : {background:'var(--theme-main-color)'}}
                onClick={(e)=>{
                    e.stopPropagation();
                    setShow(item)
                }}
            >

            </TaskCard>
        )
    }

    let mapList = (item,index) => {
        return(
            <TaskList 
                title={item.name} 
                desc={item.wbsItemName}
                key={index} 
                chip={item.isSnag ? 'Snag' : item.isPaused ? 'Paused' : item.workStarted ? 'Live' : 'Not Started'}
                backChip={item.isSnag ? {background:'var(--other-color)'} : item.isPaused ? {background:'var(--warning-color)'} : item.workStarted ? {background:'var(--success-color)'} : {background:'var(--theme-main-color)'}}
                onClick={(e)=>{
                    e.stopPropagation();
                    setShow(item)
                }}
                
            />
        )
    }


    if(loading) {
        return <PageLoader />
    }

    return(
        <>
            <FilterOutline />
            <div style={{height:'calc(100vh - 120px)',overflowY:'auto'}}>
                <PaginationTable 
                    totalPages={table.totalPages}
                    total={table.total} 
                    calendar={calendar}  
                    page={table.page}
                    data={items} 
                    mapCard={mapCard} 
                    mapList={mapList} 
                    size={100}
                    next={(page)=>{
                        INIT({page})
                    }}
                    />
            </div>
            {
                show?
                <TaskView 
                    close={()=>setShow(false)}
                    item={show}
                    isUpdate={false}
                />
                :null
            }
        </>
    )
}

export default Index;