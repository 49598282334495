import { useEffect, useState } from "react";
import Moment from 'moment';
import { TaskCard,TaskList } from "../../../Common/CardLayout/TaskCard";
import { PageLoader } from "../../../Common/PageLoader";
import { useProject } from "../../../Hooks/ProjectSelect";
import { get } from "../../../Model/Network/Config/Axios";
import { TaskView } from "../../../Component/TaskView";
import { FilterOutline } from "../../../Common/Filter/FilterOutline";
import { NormalTable } from "../../../Common/Tables/NormalTable";

const Index = () => {

    const [loading,setLoading] = useState(true);
    const [tasks,setTask] = useState([]);
    const [show,setShow] = useState(false);
    const [calendar,setCalendar] = useState([]);
    const project = useProject();

    useEffect(()=>{
        INIT();
    },[])


    let INIT = () =>{
        let url = `/task/liveTasks`
        if(project) {
            url = `/task/liveTasks?projectId=${project.id}`
        }
        get(`${url}`,(e,r)=>{
            if(r) {
                 let items = [];
                 Object.keys(r.tasks).forEach(val=>{
                    r.tasks[val].forEach(i=>{
                        items.push(i);
                    })
                })
               
                let cItems = [];
                items.forEach(val=>{
                    cItems.push({
                        start:Moment(val.estimatedStartDate).format('YYYY-MM-DD'),
                        end:Moment(val.estimatedCompletionDate).format('YYYY-MM-DD'),
                        title:val.wbsItemTaskName,
                        id:val.id,
                        backgroundColor: val.critical?'var(--danger-color)': val.isSnag?'var(--other-color)': val.isPaused?'var(--warning-color)' :'var(--success-color)',
                        borderColor: val.critical?'var(--danger-color)': val.isSnag?'var(--other-color)': val.isPaused?'var(--warning-color)' :'var(--success-color)',
                        textColor:'#fff'
                    })
                })
                setCalendar(cItems);
                setTask(items);
                setLoading(false);
            }
        })
    }

    let mapCard = (item,index) => {
        return(
            <TaskCard 
                size="M" 
                key={index} 
                title={item.task.name} 
                chip={item.isSnag ? 'Snag' : item.isPaused ? 'Paused' : item.workStarted ? 'Live' : 'Pending'}
                backChip={item.isSnag ? {background:'var(--other-color)'} : item.isPaused ? {background:'var(--warning-color)'} : item.workStarted ? {background:'var(--success-color)'} : {background:'var(--theme-main-color)'}}
                desc={item.wbsItemTaskName}
                onClick={(e)=>{
                    e.stopPropagation();
                    setShow(item)
                }}
            >

            </TaskCard>
        )
    }

    let mapList = (item,index) => {
        return(
            <TaskList 
                title={item.task.name} 
                index={index}
                key={index} 
                chip={item.isSnag ? 'Snag' : item.isPaused ? 'Paused' : item.workStarted ? 'Live' : 'Pending'}
                backChip={item.isSnag ? {background:'var(--other-color)'} : item.isPaused ? {background:'var(--warning-color)'} : item.workStarted ? {background:'var(--success-color)'} : {background:'var(--theme-main-color)'}}
                desc={item.wbsItemTaskName}
                onClick={(e)=>{
                    e.stopPropagation();
                    setShow(item)
                }}
                
            />
        )
    }


    if(loading) {
        return <PageLoader />
    }



    return(
        <>
            <FilterOutline  />
            <div style={{height:'calc(100vh - 100px)',overflowY:'auto'}}>
                <NormalTable calendar={calendar} data={tasks} mapCard={mapCard} mapList={mapList}/>
            </div>
            {
                show?
                <TaskView 
                    close={()=>setShow(false)}
                    item={show}
                    onINIT={()=>{
                        setShow(false);
                        INIT();
                    }}
                    onUpdate={()=>{
                        INIT();
                    }}
                />
                :null
            }
        </>
    )
}

export default Index;