import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';


const ImageWrapper = ({ image,style,onClick }) => (
    <div onClick={onClick?()=>onClick():{}}>
      <LazyLoadImage
        height={style.height}
        src={image} // use normal <img> attributes as props
        width={style.width} 
        style={{...style}}
        />
    </div>
  );
  
  export {ImageWrapper}