import { DateCard } from "../../Common/CardLayout/DateCard";
import { ModalHeader } from "../../Common/Title/ModalHeader";
import { useDictionary } from "../../Hooks/Dictionary";
import {KeyValue} from '../../Common/CommonCompo/KeyValue'
import { NormalButton } from "../../Common/Button/NormalButton";
import { Carousel } from "../../Common/Carousel";
import { useRef, useState } from "react";
import { post } from "../../Model/Network/Config/Axios";
import { Modal } from "../../Common/Modal/Modal";
import toast from "react-hot-toast";
import { FInput } from "../../Common/FInput";




const Index = (props) => {

    let dictionary = useDictionary();
    const [buttonLoading,setButtonLoading] = useState(false);
    const [sButtonLoading,setSButtonLoading] = useState(false);
    const [modal,setModal] = useState(false);
    let {details} = props;
    let REMARKS= useRef();

    let approve = () => {
        setButtonLoading(true);
        post(`/approvals/approve/${props.id}`, {}, (e, r) => {
            if (r) {
                setButtonLoading(false);
                toast.success('Task Approve Successfully')
                props.init();
            } else {
                toast.error(e.data.message)
                setButtonLoading(false);
            }
        })
    }

    let deleteTask = () => {
        setSButtonLoading(true);
    }


    return(
        <div style={{display:'flex',marginTop:-40}}>
            <div style={{flex:1,borderRight:'2px dashed var(--border-color)',paddingRight:30,paddingTop:30}}>
                <ModalHeader title={`${dictionary('task')} date`}  />
                <div style={{display:'flex',flexWrap:'wrap',flex:1,marginBottom:15}}>
                    <DateCard  date={details.taskMappingDetails.plannedStartDate} title="Planned Start Date" />
                    <DateCard date={details.taskMappingDetails.plannedCompletionDate} title="Planned Completion Date" />
                    <DateCard date={details.taskMappingDetails.estimatedStartDate} title="Estimated Start Date" />
                </div>
                 <div style={{display:'flex',flexWrap:'wrap',marginBottom:15}}>
                    <DateCard date={details.taskMappingDetails.estimatedCompletionDate} title="Estimated End Date" />
                     <DateCard date={details.taskMappingDetails.actualStartDate} title="Actual Start Date" />
                    <DateCard date={details.taskMappingDetails.actualCompletionDate} title="Acutal End Date" />
                </div>
                <ModalHeader title={`${dictionary('task')} details`} />
                <KeyValue title={`${dictionary('task')} Name`} value={details.taskMappingDetails.taskMappingName}/>
                <KeyValue title={`${dictionary('task')} Days Required`} value={details.taskMappingDetails.totalDaysRequired}/>
                <KeyValue title={`Lag day`} value={details.taskMappingDetails.lagDay}/>
                <ModalHeader title={`Project details`} />
                <KeyValue title={`Project Name`} value={details.projectDetails.name}/>
                <KeyValue title={`Project Description`} value={details.projectDetails.description}/>
                <KeyValue title={`Project Address`} value={details.projectDetails.address}/>
                <KeyValue title={`Project City`} value={details.projectDetails.city}/>

            </div>
            <div style={{width:"40%",padding:20}}>

                <Carousel items={props.images} layout={4} />
                {props.details.status === 'PENDING' &&
                (
                <div style={{display:'flex'}}>
                    <NormalButton text="Approve" onClick={approve} loading={buttonLoading} />
                    <NormalButton text="Decline" onClick={()=>{setModal(true)}}  style={{background:'var(--danger-color)',marginLeft:10}} />
                </div>
                )}
            </div>
            {
                modal ?
                <Modal
                    title="Decline Task"
                    show={true}
                    desc="Are you sure want to decline this task ?"
                >   
                    <div  style={{marginTop:40}}/>
                    <FInput ref={REMARKS} title="Enter Remarks" />
                    <NormalButton loading={sButtonLoading} text="Decline" onClick={()=>{
                        let reason = REMARKS.current.value;
                        if(!reason) {
                            toast.error("Please enter the remakrs");
                            return;
                        }
                        setSButtonLoading(true);
                        post(`approvals/decline/${details.approvalId}`,{reason,photos:[]},(e,r)=>{
                            if(r) {
                                toast.success("Task Declined Successfully");
                                setModal(false)
                                props.init();
                                setSButtonLoading(false);

                            } else {
                                toast.error(e.data.message)
                                setSButtonLoading(false);

                            }
                        })

                    }} style={{background:"var(--danger-color)"}} />
                </Modal>
                : null
            }
        </div>
    )
}

export default Index;