import {useNavigate,useLocation} from 'react-router-dom';
import DATA from './data';
import { buildrunLogo } from '../Images';
import { ICONS } from './ICONS';
import { useDictionary } from '../../Hooks/Dictionary';
import { useSelector } from 'react-redux';
import construction from './construction.png';
import './index.css';


const Sidebar = () => {

    let Navigation = useNavigate();
    let route = useLocation().pathname;
    let dictionary = useDictionary();
    let name = useSelector(name=>(name.Config.data.name))

    let mapItem = (item,index) => {
        let active = route.includes(item.path);
        return(
            <div key={index} className={`sidebar-item ${active?'sidebar-active':''}`} onClick={()=>{
                if(item.onClick) {
                    item.onClick()
                } else {
                    Navigation(item.path)
                }
            }}>
                <div style={{marginTop:4,marginLeft:10}}>{ICONS[item.value]}</div>
                <div style={{marginLeft:10}}>{dictionary(item.name)}</div>
            </div>
        )
    }
    return(
        <div className="sidebar">
            <div style={{
                height:66,
                display:'flex',
                alignItems:'center',
                paddingLeft:15,
            }}>
                <img src={buildrunLogo} alt="" style={{height:40,width:40}} />
                <div style={{marginTop:10,fontSize:26,fontWeight:'700'}}>
                    Buildrun
                </div>
            </div>
            <div style={{marginTop:30}}/>
            {
                DATA.map(mapItem)
            }

            <div style={{position:'fixed',left:0,width:240,bottom:0,height:80,padding:10,zIndex:100}}>
                <div style={{background:'var(--background-color)',padding:10,height:50,fontWeight:'700',borderRadius:8,display:'flex',alignItems:'center'}}>
                    <img src={construction} alt="" style={{height:40}}/>
                    <p>{name}</p>
                </div>
            </div>
        </div>
    )
}

export {Sidebar}