import  { useState } from 'react';
import { ImageModal } from './ImageModal';
import { ImageWrapper } from './ImageWrapper';


const Carousel = (props) => {

    const [modal,setModal] = useState(false);
    const [index,setIndex] = useState(undefined);


   let mapPhotos = (item, index) => {
       let width = 100/props.layout;
        return (
            <div style={{
                padding: 5,
                width: `${width}%`,
                boxSizing: 'border-box',
            }} onClick={() => {
                setIndex(index);
                setModal(true);
            }}  key={index}>
                <ImageWrapper 
                    image={item.photo?item.photo:item}
                    key={index}
                    style={{
                        width:'100%',
                        height:100,
                        borderRadius:16,
                        cursor:'pointer'
                    }}
                    onClick={()=>{
                        setIndex(index);
                        setModal(true);
                    }}
                />
            </div>
        )
    }

    return (
            <div style={{
                display: 'flex',
                flexWrap: 'wrap',
                flex:1
            }}>
                {props.items.map(mapPhotos)}
                {
                    modal ?
                        <ImageModal
                            items={props.items}
                            index={index}
                            close={() => {
                                setModal(false);
                            }}
                            details={props.details}

                        />
                        : null
                }
            </div>
        )
}

Carousel.defaultProps = {
    layout:10
}

export { Carousel };