import { useLocation } from "react-router-dom";
import { KeyValue1 } from "../../../../Common/CommonCompo/KeyValue";

function Dpr() {
    const location = useLocation();
    console.log(location.state);
    let data = location.state; 

    let square = (title,value,backgroundColor,textColor) => {
        return(
            <div style={{height:120,width:'16.66%'}}>
                <div className="flex flex-v-center flex-h-center" style={{margin:10,flexDirection:'column',textAlign:'center',color:textColor,borderRadius:4,fontWeight:600,backgroundColor,height:'inherit'}}>
                    <p style={{fontSize:14}}><span style={{fontSize:20,fontWeight:800,paddingRight:5}}>{value}</span> task</p>
                    <p style={{fontSize:14}}>{title}</p>
                </div>
            </div>
        )
    }

    let header = (item,style) => {
        return(
            <div className="flex flex-v-center flex-h-center" style={{height:'inherit',padding:2,fontWeight:"600",fontSize:13,borderRight:'1px solid var(--border-color)',...style}}>
                {item}
            </div>
        )
    }

    let row = (item,style) => {
        return(
            <div 
                className="flex flex-v-center flex-h-center" 
                style={{height:'inherit',fontSize:14,padding:2,borderRight:'1px solid var(--border-color)',...style}}
            >
                {item}
            </div>
        )
    }

    return (
        <div style={{padding: "0 20px 20px 20px", height: "calc(100vh - 85px)", overflow: "auto"}}>
            <div className="flex">
                <div style={{flex:1,marginRight:20}} className="title-container-1">
                    <p style={{fontWeight:600,fontSize:15,marginBottom:20}}>Project Completion Date</p>
                    <KeyValue1 title={'Today Estimated'} value={data.today}  />
                    <KeyValue1 title={'Yesterday Estimated'} value={data.yesterday}  />
                </div>
                <div  style={{flex:2,paddingLeft:10,paddingRight:10}} className="title-container-1">
                        <p style={{fontWeight:600,fontSize:15,marginBottom:10}}>Task Status</p>
                        <div style={{display:'flex',flexWrap:'wrap'}}>
                        {square('In Progress',data.inProgress,'rgba(235, 184, 64, .1)','#e7a918')}
                        {square('Delay',data.delayed,'rgba(234, 58, 60, .1)','#ea3a3c')}
                        {square('Completed',data.completed,'rgba(104, 208, 131, .1)','#36b056')}
                        {square('Not Started',data.notStarted,'rgba(222, 226, 230, .2)','#777')}
                        {square('Updated',data.totalUpdated,'rgba(255, 148, 77, .1)','#ff944d')}
                        {square('Added Today',data.newlyAdded,'rgba(51, 204, 204,.1)','#33cccc')}
                        </div>
                </div>
            </div>

            <div className="title-container-1">
                <p style={{fontWeight:600,fontSize:15,marginBottom:20}}>MIS Score</p>
                <div style={{border:'1px solid var(--border-color)',display:'flex',height:40,background:"var(--background-color)"}}>
                    {header('Employee Name',{flex:1})}
                    {header('Live Task Updated',{flex:1})}
                    {header('Actually Started/ Due Start',{flex:1})}
                    {header('Actually Finished/Due Finished',{flex:1})}
                </div>
                {
                    data.misScore.map((item,index)=>{
                        return(
                            <div key={index} style={{border:'1px solid var(--border-color)',display:'flex',height:50}}>
                                {row(item.empName,{flex:1})}
                                {row(item.liveTaskUpdate,{flex:1})}
                                {row(item.dueStartActuallyStarted,{flex:1})}
                                {row(item.dueFinishActuallyFinished,{flex:1})}
                            </div>
                        )
                    })
                }
            </div>

            <div className="title-container-1">
                    <p style={{fontWeight:600,fontSize:15,marginBottom:20}}>Task Updates</p>
                    <div style={{border:'1px solid var(--border-color)',display:'flex',height:40,background:"var(--background-color)"}}>
                        {header('Task',{flex:2})}
                        {header('Doer',{flex:1})}
                        {header('ES/AS',{flex:0.7})}
                        {header('EF/AF',{flex:0.7})}
                        {header('Started',{flex:0.5})}
                        {header('Updated',{flex:0.5})}
                        {header('Completed',{flex:0.5})}
                        {header('Not Started',{flex:0.5})}
                        {header('Not Updated',{flex:0.5})}
                        {header('Not Completed',{flex:0.5})}
                        {header('Critical',{flex:0.5})}
                    </div>
                    {
                        Object.keys(data.uniqueTasks).map((val,index)=>{
                            return(
                                <div key={index} style={{border:'1px solid var(--border-color)',display:'flex',height:50}}>
                                    {row(val,{flex:2,fontSize:13,justifyContent:'flex-start',cursor:'pointer',fontWeight:'600'},true)}
                                    {row(data.uniqueTasks[val].person,{flex:1})}
                                    {row(data.uniqueTasks[val].startDate,{flex:0.7})}
                                    {row(data.uniqueTasks[val].endDate,{flex:0.7})}
                                    {row(data.uniqueTasks[val].started,{flex:0.5,fontSize:13})}
                                    {row(data.uniqueTasks[val].updates,{flex:0.5,fontSize:13})}
                                    {row(data.uniqueTasks[val].completed,{flex:0.5,fontSize:13})}
                                    {row(data.uniqueTasks[val].taskNotStarted,{flex:0.5,fontSize:13})}
                                    {row(data.uniqueTasks[val].taskNotUpdated,{flex:0.5,fontSize:13})}
                                    {row(data.uniqueTasks[val].taskNotCompleted,{flex:0.5,fontSize:13})}
                                    {row(data.uniqueTasks[val].critical,{flex:0.5,fontSize:13})}
                                </div>
                            )
                        })
                    }
            </div>
        </div>
    )
}

export default Dpr;
