import  { useState } from 'react';
import close from './close.png';
import left from './left.png';

const ImageModal = ({items,showDetails,...props}) => {

    const [index,setIndex] = useState(props.index);


    return (
            <div style={{
                position: 'fixed',
                right: 0,
                top: 0,
                left: 0,
                bottom: 0,
                backgroundColor: "rgba(0,0,0,0.9)",
                zIndex: 100
            }}>
                {
                    index !== 0 ?

                        <div style={{
                            position: 'absolute',
                            left: 20,
                            display: 'flex',
                            alignItems: 'center',
                            height: '100vh',
                        }}>
                            <div style={{
                                height: 50,
                                width: 50,
                                backgroundColor: 'rgba(255,255,255,0.5)',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRadius: '50%',
                                cursor: 'pointer'
                            }} onClick={() => {
                                setIndex(index-1);
                            }}>
                                <img src={left} alt="" style={{
                                    height: 30,
                                    width: 30,

                                }} />
                            </div>

                        </div>
                        : null
                }
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: "100vh"
                }}>
                    <div style={{
                        width: '80%',
                        backgroundColor: '#fff',
                        height: '75vh',
                        position: 'relative'
                    }}>
                        <div style={{
                            position: 'absolute',
                            right: 10,
                            top: 10,
                            height: 40,
                            width: 40,
                            backgroundColor: 'rgba(255,255,255,0.5)',
                            borderRadius: '50%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            cursor: 'pointer'
                        }} onClick={props.close}>
                            <img src={close} alt="" style={{
                                height: 15,
                                width: 15
                            }} />
                        </div>
                        <img src={items[index].photo?items[index].photo:items[index]}
                            style={{
                                height: '100%',
                                width: '100%',
                                borderRadius: 4
                            }}
                            alt=""
                        />
                    </div>

                    {
                        items.length !== index + 1 ?
                            <div style={{
                                color: '#fff',
                                position: 'absolute',
                                right: 20
                            }}>
                                <div style={{
                                    height: 50,
                                    width: 50,
                                    backgroundColor: 'rgba(255,255,255,0.5)',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    borderRadius: '50%',
                                    cursor: 'pointer'
                                }} onClick={() => {
                                    if (items.length >= index + 1)
                                        setIndex(index+1);
                                }}>
                                    <img src={left} alt="" style={{
                                        height: 30,
                                        width: 30,
                                        transform: 'rotate(180deg)'

                                    }}  />
                                </div>
                            </div>
                            : null
                    }
                </div>
            </div>
        )
}

ImageModal.defaultProps = {
    showDetails: false
}

export { ImageModal };