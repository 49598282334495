import Props from 'prop-types';

const Wrapper = (props) => {
    return(
        <div style={{background:"var(--white-color)",height:'100vh',display:'flex',}}>
            <div style={{backgroundColor:'var(--background-color)',zIndex:100,position:'relative',width:'calc(100vw - 20px)',borderBottomRightRadius:32,height:'calc(100vh - 20px)',display:'flex'}}>
                {props.children}
            </div>
        </div>
    )
}

Wrapper.propsTypes = {
    children:Props.any
}

export {Wrapper}