import Props from 'prop-types';
import { NormalLoadingButton } from '../NormalLoadingButton';
import './index.css';

const NormalButton = ({text,onClick,loading,style}) => {

    
    if(loading) {
        return <NormalLoadingButton style={{...style}}/>
    }

    return(
        <div className="button" style={{...style}} onClick={onClick}>
            {text}
        </div>
    )
}

NormalButton.defaultProps = {
    style:{}
}

NormalButton.propsTypes = {
    text:Props.string.isRequired,
    onClick:Props.string.isRequired,
    loading:Props.bool,
    style:Props.object
}

export {NormalButton}