import './index.css';

const BoxWrapper = (props) => {
    return(
        <div style={{
            padding:12,
            ...props.style,
        }}>
         <div style={{
            width:'100%',
            background:'#fff', 
            borderRadius:12,
            height:'100%',
            padding:25,
            ...props.innerStyle
         }} className="box-wrapper-shadow">
            {props.children}
        </div>
        </div>
    )
}

export {BoxWrapper}