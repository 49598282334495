import {useSelector} from 'react-redux';

const useDictionary = () => {
    const {dictionaries} = useSelector(state=>(state.Config.data))

    let lowerCaseDictionary = {};
    
    Object.keys(dictionaries).forEach((val)=>{
        lowerCaseDictionary[val.toLowerCase()] = dictionaries[val]
    })
    
    const getDictionary = (value) => {
        if(lowerCaseDictionary) {
            return lowerCaseDictionary[value.toLowerCase()] ? lowerCaseDictionary[value.toLowerCase()]:value
        } else {
            return value;
        }
    }

    return getDictionary;
}

export {useDictionary}