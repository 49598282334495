import { DateCard } from "../../Common/CardLayout/DateCard";
import { ModalHeader } from "../../Common/Title/ModalHeader";
import { useDictionary } from "../../Hooks/Dictionary";
import {KeyValue} from '../../Common/CommonCompo/KeyValue'
import UpdateTask from "./UpdateTask";


const Index = (props) => {

    let dictionary = useDictionary();
    let {details,isUpdate} = props;
    return(
        <div style={{display:'flex',marginTop:-40}}>
           <div style={{flex:1,borderRight:'2px dashed var(--border-color)',paddingRight:30,paddingTop:30}}>
            <ModalHeader title={`${dictionary('task')} date`}  />
            <div style={{display:'flex',flexWrap:'wrap',flex:1,marginBottom:15}}>
                <DateCard  date={details.plannedStartDate} title="Planned Start Date" />
                <DateCard date={details.plannedCompletionDate} title="Planned Completion Date" />
                <DateCard date={details.estimatedStartDate} title="Estimated Start Date" />
            </div>
            <div style={{display:'flex',flexWrap:'wrap',marginBottom:15}}>
                <DateCard date={details.estimatedCompletionDate} title="Estimated End Date" />
                <DateCard date={details.actualStartDate} title="Actual Start Date" />
                <DateCard date={details.actualCompletionDate} title="Acutal End Date" />
            </div>
            <ModalHeader title={`${dictionary('task')} details`} />
            <KeyValue title={`${dictionary('task')} Name`} value={details.task.name}/>
            <KeyValue title={`${dictionary('task')} Type`} value={details.task.taskType}/>
            <KeyValue title={`${dictionary('wbs')} Name`} value={details.wbsItemTaskName}/>
            <KeyValue title={`${dictionary('wbs')} Item Mapping`} value={details.wbsItemMapping.name}/>

            {
                details.approver?
                <>
                    <ModalHeader title={`Approver details`} />
                    <KeyValue title={`Name`} value={details.approver.name}/>
                    <KeyValue title={`Email`} value={details.approver.email}/>
                    <KeyValue title={`Mobile Number`} value={details.approver.mobile}/>

                </>
                : null
            }

            {
                details.predecessorTask ?
                <>
                    <ModalHeader title={`Predecessor Task details`} />
                    <KeyValue title={`${dictionary('wbs')} Name`} value={details.predecessorTask.wbsItemTaskName}/>
                    <KeyValue title={`Relation with Task`} value={details.predecessorTask.predecessorRelation}/>
                    <KeyValue title={`Work Status`} value={details.predecessorTask.workStarted?'Started':"Not Started"}/>
                </>
                :null
            }

            {
                details.successorTasks && details.successorTasks.length ?
                <>
                    {
                        details.successorTasks.map((val,index)=>(
                        <div key={index}>
                            <ModalHeader title={`Successor ${dictionary('task')} ${index+1}`} />
                            <KeyValue title={`${dictionary('task')} name`} value={val.successorTaskName}/>
                            <KeyValue title={`Successor Relationship`} value={val.successorRelation}/>

                        </div>))
                    }
                </>

                :null

            }
            </div>
            <div style={{width:'40%',padding:20}}>
                {isUpdate?
                    <UpdateTask finish={props.finish} init={props.init} details={details} />
                    :null
                }
            </div>

        </div>
    )
}

Index.defaultProps = {
    isUpdate:true
}

export default Index;