import { useEffect } from 'react';
import {Routes,Route} from 'react-router-dom';
import Mixpanel from 'mixpanel-browser';
import { Sidebar } from "../Common/Sidebar";
import { Wrapper } from "../Common/Wrapper";
import Home from './Home'
import Task from './Task';
import Approval from './Approvals';
import Profile from './Profile';
import {  useSelector } from 'react-redux';
import { PageLoader } from '../Common/PageLoader';
import Attendance from './Attendance';


const Index = () => {

    let config = useSelector(state=>(state.Config.data));

    useEffect(()=>{
        Mixpanel.init('a2e4f87394e324411d20e7083dba76b1',{debug:false})
        Mixpanel.track('Login Enabled',{})
    },[])

    if(!config) {
        return <PageLoader />
    }

    return(
        <Wrapper>
            <Sidebar />
            <div style={{flex:1}}>
                <Routes>
                    <Route element={<Home />} path="home/*" />
                    <Route element={<Task />} path="task/*" />
                    <Route element={<Attendance />} path="attendance/*" />
                    <Route element={<Approval />} path="approval/*" />
                    <Route element={<Profile />} path="profile/*" />
                </Routes>
            </div>
        </Wrapper>
    )
}

export default Index;