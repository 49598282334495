

const KeyValue = (props) => {

    return(
        <div style={{
            border:'1px solid var(--border-color)',
            padding:'8px 12px',
            borderRadius:4,
            marginBottom:14
        }}>
            <p style={{fontSize:12,letterSpacing:0.5,color:'var(--text-light-color)'}}>{props.title}</p>
            <p style={{fontSize:16}}>{props.value}</p>
        </div>
    )
}


const KeyValue1 = (props) => {

    return(
        <div style={{
            padding:'8px 12px',
            borderRadius:4,
            marginBottom:0,
            ...props.style
        }}>
            <p style={{fontSize:12,letterSpacing:0.5,color:'var(--text-light-color)'}}>{props.title}</p>
            <p style={{fontSize:16}}>{props.value}</p>
        </div>
    )
}


export {KeyValue,KeyValue1}