
const data = [
    {
        name:'Home',
        path:'/app/home',
        value:'home'
    },
    {
        name:'Task',
        path:'/app/task',
        value:'task'

    },
    {
        name:'Attendance',
        path:'/app/attendance',
        value:'attendance'

    },
    {
        name:'Approvals',
        path:'/app/approval',
        value:'approval'

    },
    {
        name:'Profile',
        path:'/app/profile',
        value:'profile'
    },
    {
        name:'Logout',
        path:"/app/logout",
        value:'logout',
        onClick:()=>{
            window.localStorage.clear();
            window.location.reload()
        }
    }
]

export default data;