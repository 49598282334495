import { useRef, useState } from "react";
import toast from "react-hot-toast";
import { NormalButton } from "../../../Common/Button/NormalButton";
import { SearchProject } from "../../../Common/Search/SearchProject";
import { SearchWbsItem } from "../../../Common/Search/SearchWbsItem";
import { BoxWrapper } from "../../../Common/Wrapper/BoxWrapper";
import { post } from "../../../Model/Network/Config/Axios";
import Report from './Report';

const Mis = () => {


    const [buttonLoading,setButtonLoading] = useState(false);
    const [data,setData] = useState(undefined);
    let PROJECT = useRef(),WBSITEM=useRef();


    let generateReport = () =>{
        let projectId = PROJECT.current.getValue()?PROJECT.current.getValue().id:undefined;
        if(!projectId) {
            toast.error("Please select the project");
            return;
        }
        let wbsId = WBSITEM.current.getValue();
        let filters=[];
        if(wbsId) {
                filters.push({ key:'WBS', val:wbsId.wbsItemName})
        } else {
            toast.error("Please select wbs Items");
            return;
        }

        setButtonLoading(true)
        post(`/task/taskStatusMisDump/${projectId}`,{filters},(e,r)=>{
            if(r) {
                setData(r)
                setButtonLoading(false);
            }
        })

    }

    if(data) {
        return(
            <Report 
                data={data}
                onBack={()=>{
                    setData(undefined);
                }}
            />
        )
    }

    return(
        <BoxWrapper innerStyle={{borderRadius:4}} style={{padding:30}}>
            <SearchProject ref={PROJECT} title="Search Project" />
            <SearchWbsItem projectId={PROJECT}  ref={WBSITEM} title="Search Wbs Item" />
            <NormalButton loading={buttonLoading} onClick={generateReport} text="Generate Report" />
        </BoxWrapper>
    )
}

export default Mis;
