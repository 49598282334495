import { forwardRef, useEffect, useRef, useState,useImperativeHandle } from "react"
import { get } from "../../../Model/Network/Config/Axios";
import { FInput } from "../../FInput"


const SearchEmployee = forwardRef((props,ref) =>{


    const [text,setText] = useState('');
    const [employee,setEmployees] = useState([]);
    const [item,setItem] = useState(undefined);
    let NAME = useRef();

    useImperativeHandle(ref,()=>({
        getValue:()=>{
            return item
        },
        setValue:()=>{
            setItem('')
        }
    }))

    useEffect(()=>{
        if(text.length>0) {
            get(`task/adhocTask/employee.search`, (e, r) => {
                if (r) {
                   setEmployees(r.employees)
                }
            }, {
                q: text
            })
        } else {
            setEmployees([])
        }

    },[text])



    return(
        <div style={{position:'relative'}}>
            <FInput ref={NAME} title={props.title} onChange={setText} />
            {
                employee.length>0 ?
                <div className="suggestion-modal" style={{marginTop:21}}>
                    {employee.map((val,index)=>(
                    <div key={index} className="suggestion-modal-item" onClick={()=>{
                        setItem(val);
                        NAME.current.value = val.name;
                        setEmployees([]);
                        }}>
                        <div>
                            <p>{val.name}</p>
                            <p style={{fontSize:12,color:'var(--text-light-color)'}}>{val.email}</p>
                        </div>
                    </div>))}
                </div>:
                null
            }
        </div>
    )
})

export {SearchEmployee}