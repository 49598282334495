import { Carousel } from "../../Common/Carousel";



const Index = (props) => {


    let mapTimeLine = (val,index) => {
        console.log(val,props.timeline[val]);
        let firstPhotos = [];
        firstPhotos.push(props.timeline[val]["FIRST"]?props.timeline[val]["FIRST"].photo:props.timeline[val]["WORKDONE"].photo);
        if(props.timeline[val]['FIRST']) {
            firstPhotos.push(...props.timeline[val]['FIRST'].photos)
        } else {
            firstPhotos.push(props.timeline[val]['WORKDONE'].photos)
        }

        return(
            <div key={index} style={{border:'1px solid var(--border-color)',position:'relative',padding:16,marginBottom:40,borderRadius:4}}>
                <div style={{flex:1,textAlign:'center',top:-27,position:'relative'}}>
                    <span style={{top:0,textAlign:'center',flex:1,fontSize:20,color:'var(--text-light-color)',marginTop:-28,background:"#fff",padding:'0px 20px',fontWeight:"700"}}>{val}</span>
                </div>
                <Carousel items={firstPhotos} />
            </div>
        )
    }

    return(
        <div>
            {Object.keys(props.timeline).reverse().map(mapTimeLine)}
            
        </div>
    )
}

export default Index;