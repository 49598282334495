import { useRef, useState } from "react";
import toast from "react-hot-toast";
import { NormalButton } from "../../../Common/Button/NormalButton";
import { SearchHinderance } from "../../../Common/Search/SearchHinderance";
import { SearchProject } from "../../../Common/Search/SearchProject";
import { SearchWbs } from "../../../Common/Search/SearchWbs";
import { BoxWrapper } from "../../../Common/Wrapper/BoxWrapper";
import { post } from "../../../Model/Network/Config/Axios";


const Index = () => {


    const [buttonLoading,setButtonLoading] = useState(false);
    let PROJECT = useRef(),WBS=useRef(),HINDERANCE=useRef();


    let submit = () => {
        let hindranceId = HINDERANCE.current.getValue()?HINDERANCE.current.getValue().hindranceId:null;
        let taskMappingId = WBS.current.getValue()?WBS.current.getValue().id:null

        if(!hindranceId) {
            toast.error("Please select the hinderance Type");
            return;
        }

        if(!taskMappingId) {
            toast.error("Please select the wbs item");
            return;
        }
        
        setButtonLoading(true);

        post(`/misc/tagHindrance`,{hindranceId,taskMappingId},(e,r)=>{
            if(r) {
                setButtonLoading(false);
                HINDERANCE.current.setValue('');
                WBS.current.setValue('');
                PROJECT.current.setValue('');

                toast.success('Hinderance raise successfully');
              
            } else {
                setButtonLoading(false);
            }
        })


    }

    return(
        <BoxWrapper innerStyle={{borderRadius:4}} style={{margin:20}}>
            <SearchHinderance ref={HINDERANCE} title="Search Hinderance" />
            <SearchProject ref={PROJECT} title="Search Project" />
            <SearchWbs projectId={PROJECT} ref={WBS} title="Search Wbs" />
            <NormalButton loading={buttonLoading} onClick={submit} text="Submit" style={{maxWidth:350}}/>
        </BoxWrapper>
    )
}

export default Index;