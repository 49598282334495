import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react"
import { get } from "../../../Model/Network/Config/Axios";
import { FInput } from "../../FInput"
import {toast} from 'react-hot-toast';


const SearchWbsItem = forwardRef((props,ref) =>{


    const [text,setText] = useState('');
    const [wbs,setWbs] = useState([]);
    const [item,setItem] = useState(undefined);
    let NAME = useRef();

   

    useImperativeHandle(ref,()=>({
        getValue:()=>{
            return item
        }
    }))

    useEffect(()=>{
        if(text.length>0) {
            get(`/task/searchTaskMapping/${props.projectId.current.getValue().id}`, (e, r) => {
                if (r) {
                   setWbs(r.tasks)
                }
            }, {
                q: text
            })
        } else {
            setWbs([])
        }

    },[text])


    return(
        <div style={{position:'relative'}}>
            <FInput ref={NAME} title={props.title} onChange={(val)=>{
                if(!props.projectId.current.getValue()) {
                    toast.error('Please select project first')
                    NAME.current.value = ''
                    return;
                    
                } else {
                    setText(val)
                }
            }} />
            {
                wbs.length>0 ?
                <div className="suggestion-modal" style={{marginTop:21}}>
                    {wbs.map((val,index)=>(
                    <div key={index} className="suggestion-modal-item" onClick={()=>{
                        setItem(val);
                        NAME.current.value = val.wbsItemName;
                        setWbs([]);
                        }}>
                        <div>
                            <p>{val.wbsItemName}</p>
                            <p style={{fontSize:12,color:'var(--text-light-color)'}}>{val.taskName}</p>
                        </div>
                    </div>))}
                </div>:
                null
            }
        </div>
    )
})

export {SearchWbsItem}