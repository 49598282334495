

const Tab = ({items}) => {

    return(
        <div style={{display:'flex',flex:1,background:"var(--background-color)",height:40,borderRadius:12}}>
            {items.map((val,index)=>{
                return(
                <div onClick={val.onClick} key={index} style={{flex:1,textAlign:'center',borderRadius:12,cursor:"pointer",display:'flex',alignItems:'center',justifyContent:'center',...val.active?{background:"var(--theme-main-color)",color:"#fff",fontWeight:'600'}:{}}}>
                    {val.name}
                </div>)
            })}
        </div>
    )
}

export {Tab}