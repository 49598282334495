import { useEffect, useState } from "react";
import PieGraph from "../../../../Common/Graphs/PieGraph";
import { LineGraphLabels } from "../../../../Common/Graphs/LineGraphLabels";
import { BoxWrapper } from "../../../../Common/Wrapper/BoxWrapper";
import { useDictionary } from "../../../../Hooks/Dictionary";
import { useProject } from "../../../../Hooks/ProjectSelect";
import { get } from "../../../../Model/Network/Config/Axios";


const Index = () => {

    const [metaData,setMetaData] =useState({});
    const project = useProject();
    const dictionary = useDictionary();

    useEffect(()=>{
        let url = `task/analytics.meta`;
        if(project) {
            url = `task/analytics.meta?projectId=${project.id}`
        }
        get(`${url}`,(e,r)=>{
            if(r) {
                let obj = {};
                obj.totalTasks = r.totalTasks;
                obj.OngoingTasks = r.totalOngoingTasks;
                obj.totalCriticalTask = r.totalCriticalTasks;
                obj.totalCompletedTasks = r.totalCompletedTasks
                setMetaData(obj);
            }
        })
    },[])

    return(
        <div style={{
            display:'flex',
            flexWrap:'wrap'
        }}>
            <BoxWrapper  style={{height:320,width:'33.33%'}}>
                <PieGraph title={`Total number of ${dictionary('task')}`} items={Object.keys(metaData).map(i=>i)} data={Object.keys(metaData).map(i=>metaData[i])} />
            </BoxWrapper>
            <BoxWrapper  style={{height:320,width:'66.66%'}}>
                <LineGraphLabels height={300} title="Task Staus"/>
            </BoxWrapper>

        </div>
    )
}

export default Index;