import { useEffect, useState } from "react";
import { KeyValue1 } from "../../Common/CommonCompo/KeyValue";
import { PageLoader } from "../../Common/PageLoader";
import { get } from "../../Model/Network/Config/Axios";



const Index  = (props) => {

    let {taskId} = props;

    const [loading,setLoading] = useState(true);
    const [resource,setResources] = useState([]);

    useEffect(()=>{
        INIT(taskId)
    },[taskId])

    let INIT = (id) => {
        get(`/task/fetchTaskResources/${id}`,(e,r)=>{
            if(r)  {
                setResources(r.resources)
                setLoading(false);
            }
        })
    }

    let mapItem = (item,index) => {
        return(
            <div key={index} style={{padding:'0px 10px',marginBottom:20,width:'33.33%'}}>
                <div style={{border:'1px solid var(--border-color)',padding:15,borderRadius:4}}>
                    <KeyValue1 title="Name" value={item.name} />
                    <KeyValue1 title="Description" value={item.description} />
                    <div style={{display:'flex',flex:1}}>
                        <KeyValue1 title="Resource Type" value={item.resourceType} style={{flex:1}} />
                        <KeyValue1 title="Sub Type" value={item.subType} style={{flex:1}} />
                    </div>
                    <div style={{display:'flex',flex:1}}>
                        <KeyValue1 title="Planned Quantity" value={`${item.qty} ${item.units}`} style={{flex:1}} />
                        <KeyValue1 title="Category" value={item.category} style={{flex:1}} />
                    </div>
                    <div style={{display:'flex',flex:1}}>
                        <KeyValue1 title="Actual Acquired Quantity" value={`${item.actualAcquiredQty} ${item.units}`} style={{flex:1}} />
                        <KeyValue1 title="Actual Used Quantity" value={`${item.actualUsedQty} ${item.units}`} style={{flex:1}} />
                    </div>

                </div>
            </div>
        )
    }


    if(loading) {
        return <PageLoader />
    }

    return(
        <div>
            <div style={{display:'flex',flexWrap:'wrap'}}>
                {resource.map(mapItem)}
            </div>
        </div>
    )
}

export default Index;